.font-600 {
  font-weight: 600 !important;
}

.head-pt {
  padding-top: 60px;
}

.dark .head-pt {
  padding-top: 105px;
}

.dark .fp-pt {
  padding-top: 32px;
}

.dark .enable2fa-pt {
  padding-top: 36px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open {
  background: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

/* Header Toggle */

.dark .react-tel-input .selected-flag:hover,
.dark .react-tel-input .selected-flag:focus {
  background-color: #151717 !important;
}
.security_text {
  display: flex;
  gap: 2px;
}
.security_text img {
  height: 20px;
}
.sitecolor {
  color: #33c2b9 !important;
}

.stake-toggle {
  width: fit-content;
  border: 1px solid #d0d0d0;
  margin-left: 15px;
  display: flex;
  margin: 0px 3px;
}

.stake-toggle .usd_btn {
  padding: 5px 10px;
  background-color: #33c2b9;
  color: #fff;
  border: none;
}

.stake-toggle .Eur_btn {
  padding: 5px 10px;
  /* border-radius: 50px; */
  background-color: transparent;
  color: #838790;
  border: none;
}

.header-toggle-cont {
  padding: 3px;
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #d0d0d0;
  /* margin-left: 15px; */
  display: flex;
  /* margin: 0px 3px; */
}

.header-toggle-cont button {
  border: none;
  outline: none;
  font-family: "Satoshi";
}

.header-toggle-cont .light_btn {
  padding: 5px 10px;
  border-radius: 50px;
  background-color: #33c2b9;
  color: #fff;
  border: none;
}

.header-toggle-cont .pro_btn {
  padding: 5px 10px;
  border-radius: 50px;
  background-color: transparent;
  color: #838790;
  border: none;
}

/* .MuiToolbar-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

/* Landing styles */

.services h3 {
  margin-bottom: 12px;
  font-family: "Satoshi" !important;
  font-weight: 600;
}

.services li {
  margin-bottom: 16px;
}

.frame-container {
  width: 285px;
  /* height: 562px; */
  padding: 20px 20px 19px;
  background-color: #fff;
  font-family: "Satoshi" !important;
}

.user-details {
  border-bottom: solid 1px rgba(208, 208, 208, 0.5);
  width: 245px;
  height: 63px;
  display: flex;
  gap: 8px;
  margin: 0 0 12px;
  padding: 0;
  font-family: "Satoshi";
}

.user-img {
  width: 28.1px;
  height: 28.1px;
}

.details {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}

.links {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-bottom: solid 1px rgba(208, 208, 208, 0.5);
}

.links > li {
  list-style-type: none;
  padding: 13px 5px 13px 9px;
}

.links > li > a {
  text-decoration: none;
  color: rgba(17, 24, 39, 0.8);
}

.links > li:hover {
  background-color: #f7f7f7;
  border-radius: 5px;
}

.dark .links > li:hover {
  background-color: transparent !important;
  border-radius: 5px;
  border: 1px solid #33c2b9;
}

.dark .links > li:hover .profile-link-arrow img {
  filter: brightness(0) saturate(100%) invert(65%) sepia(7%) saturate(3278%)
    hue-rotate(127deg) brightness(97%) contrast(97%);
}

.links > li:hover a {
  color: #000 !important;
}

.dark .links > li:hover a {
  color: #fff !important;
}

.profile-link-arrow {
  display: none;
}

.links > li:hover .profile-link-arrow {
  display: block;
}

.security-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.btn-wrapper {
  background-color: #f7f7f7;
  margin-top: 18px;
}

.user-btn {
  height: 44px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  border: none;
  color: #f84960;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.details-mail {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  text-align: left;
  color: #111827;
}

.details-udi {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  text-align: left;
  color: rgba(17, 24, 39, 0.5);
}

.details-udi > i {
  margin-left: 5px;
  color: #33c2b9;
}

.identify {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: green;
}

.identify.not-verify {
  color: #f84960;
}

.not-verify-color {
  color: #f84960 !important;
}
.verify_medium-color {
  color: #ff9a46 !important;
}
.identify > img {
  width: 20px;
}

.logout-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.arrow {
  width: 17px;
  height: 17px;
  margin-left: 6px;
}

/* staking page */

.font_14 {
  font-size: 14px; /* common font*/
}

.font-satoshi {
  font-family: "Satoshi", sans-serif !important; /* common font*/
}

.staking-hero {
  background-image: url("./assets/staking-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
}

.stake-wrapper {
  display: flex;
  align-items: center;
}

.refeferal_section_content .stake-title {
  font-size: 36px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.72px;
  text-align: left;
  color: #fff !important;
}

.stake-para {
  font-size: 16px;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
  width: 100%;
}

.staking-rewards {
  background: url("./assets/invite_earn_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 20px;
  margin-top: 30px !important;
}

.staking-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stake-asset {
  font-size: 13px;
  letter-spacing: normal;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
  font-weight: 400;
  font-family: "Satoshi" !important;
}

.stake-arrow {
  margin-left: 3px;
  color: #33c2b9;
}

.stake-sub-asset {
  font-size: 12px;
  text-align: left;
  color: #33c2b9;
}

.stake-price {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #33c2b9;
  overflow: scroll;
}

.ref_view_main span {
  color: #33c2b9;
  font-size: 13px;
  margin-bottom: 0;
}

.dark .ref_view_main span {
  color: #33c2b9;
}

.dark .total-ref-img {
  filter: brightness(0) saturate(100%) invert(61%) sepia(94%) saturate(301%)
    hue-rotate(127deg) brightness(87%) contrast(95%);
}

.stake-total {
  font-size: 12px;
  margin-top: 0;
  color: #414652;
  font-weight: 400;
}

.stake-profit {
  font-family: "Satoshi" !important;
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
}

.stake-profit-num {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
}

.stake-profit-total {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #111827;
}

/* stake option styles */

.stake-input {
  padding: 8px;
  border-radius: 8px;
  background-color: #f5f6f8;
  border: none;
  outline: none;
  text-indent: 24px;
}

.opt-title {
  font-family: Bitter;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #111827;
  margin: 20px 0px !important;
  /* margin: 0px !important; */
}

.opt-select {
  padding: 8px 10px;
  border-radius: 4px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(17, 24, 39, 0.7);
}

.custom-select-stake {
  position: relative;
  display: inline-block;
  width: 200px;
}

.stake-opt-select {
  padding: 8px 12px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  border-radius: 5px;
  outline: none;
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 100%;
  appearance: none;
  cursor: pointer;
}

.stake-search-container {
  position: relative;
}

.stake-search-container > i {
  position: absolute;
  left: 11px;
  top: 10px;
  color: rgba(17, 24, 39, 0.4);
}

.ref_view_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  /* border: 1px solid rgba(208, 208, 208, 0.5); */
  justify-content: end;
}

.table-cont {
  padding: 14px 14px 14px;
  border-radius: 12px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  margin-top: 10px;
}

.table-cont::-webkit-scrollbar-track,
.history-lists::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: "block" !important;
}

.table-cont::-webkit-scrollbar-thumb,
.history-lists::-webkit-scrollbar-thumb {
  background: #33c2b9;
  border-radius: 10px;
  display: "block" !important;
}

.table-cont::-webkit-scrollbar-thumb:hover,
.history-lists::-webkit-scrollbar-thumb:hover {
  background: #33c2b9;
}

.dark .paginate-one {
  color: #fff;
}

.table-action {
  /* display: flex;
  justify-content: end; */
  text-align: end;
}

.stake-opt-dropdown {
  cursor: pointer;
}

.dark .stake-opt-dropdown .css-13cymwt-control {
  border: none !important;
}

.dark .stake-opt-dropdown .css-t3ipsp-control {
  background: #151717 !important;
  border: 1px solid #727373 !important;
}

.table-flex {
  display: flex;
  align-items: center;
  border: none;
}

.table-flex > img {
  width: 28px;
  height: 28px;
}

table > thead,
tfoot > tr {
  border: 1px solid white;
}

table > tbody > tr {
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.stake-head {
  font-size: 12px;
  background-color: rgb(227 231 241 / 70%);
}

.stake-head.stake-table-row > th {
  padding-left: 20px !important;
  padding-right: 30px !important;
}

.dark .stake-head {
  font-size: 12px;
  background-color: rgb(66 64 64 / 50%);
}
.stake-head > th {
  padding: 10px 14px !important;
  color: rgba(17, 24, 39, 0.7);
  font-weight: 400;
}
.secuirty_box_content.refer_content.referal_box {
  padding: 8px 22px;
}

.opt-nowrap {
  text-wrap: nowrap;
}

.txt-center {
  text-align: center;
}

.opt-name {
  color: #111827;
  margin: 0px !important;
}

.opt-sub {
  font-weight: normal;
  margin: 0px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(17, 24, 39, 0.5);
}

.opt-percent {
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #33c2b9;
}

.opt-term {
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
}

.table-opt-name {
  margin-left: 10px;
}

.opt-btn {
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff !important;
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: #33c2b9;
}
.opt-btn-reward {
  padding: 3px 9px;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff !important;
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: #33c2b9;
  margin-left: 13px;
}

.paginate {
  color: rgba(17, 24, 39, 0.5);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15.5px;
  cursor: pointer;
}

.paginate-one {
  color: #111827;
}

/* faq */

.faq {
  padding: 20px;
  border-radius: 12px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
  padding: 19px 0px;
  font-family: "Satoshi" !important;
}

.faq-title {
  font-family: "Satoshi" !important;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #111827;
  margin-bottom: 2px !important;
}

.accordion-item {
  border: 1px solid white;
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
  padding: 10px 0;
  color: #111827;
  font-size: 14px;
}

.accordian-button {
  color: #111827;
  font-size: 14px;
}

.accordian-button::after {
  width: 6px;
  height: 6px;
  content: "";
  background-image: url("./assets/down_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("./assets/down_arrow.png");
}

/* Modal styles - start*/

.support-modal {
  padding: 24px;
}

.support-left-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  padding-bottom: 15px;
}

.support-modal-title {
  font-family: "Bitter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin: 0px;
}

.support-left-flex i {
  font-size: 24px;
  cursor: pointer;
}

.support-modal-top {
  margin-top: 20px;
}

.support-modal-top h6 {
  font-family: "Satoshi", sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: rgba(17, 24, 39, 0.7);
}

.support-modal-top p {
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #111827;
  border-bottom: 1px solid rgb(227 231 241 / 70%);
  padding-bottom: 20px;
  overflow-wrap: anywhere;
}

.modals {
  width: 845px !important;
  /* height: 513px !important; */
  border-radius: 12px !important;
  overflow: hidden;
  border: none !important;
  padding: 0px !important;
}

.modals_support {
  width: 500px !important;
  /* height: 513px !important; */
  border-radius: 12px !important;
  overflow: hidden;
  border: none !important;
  outline: none !important;
  padding: 0px !important;
}

.right-col {
  background-color: #f5f6f8 !important;
  overflow: hidden !important;
  padding-bottom: 20px;
  height: auto !important;
}

.pad--border {
  padding: 10px 0;
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.bitcoin-img {
  width: 24px;
  height: 24px;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.modal-titles,
.modal-titles-green {
  font-family: "Satoshi" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(17, 24, 39, 0.5);
}

.modal-titles-green {
  color: #33c2b9;
  margin: 0px;
}

.modal-right-titles {
  font-family: "Satoshi" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
  font-weight: 500;
  margin: 0px;
}

.modal-left-header,
.modal-right-header {
  padding: 5px;
  margin: 20px 0px;
}

.modal-right-header > i {
  font-size: 21.8px;
  cursor: pointer;
}

.modal-left-flex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 0px;
}

.modal-left-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 14px;
  border-top: 1px solid rgba(208, 208, 208, 0.5);
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.modal-selectDate {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 0px 19px 0px;
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.modal-select-title {
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
}

.modal-right-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 8px; /*will be changed*/
}

.modal-flex {
  display: flex;
  gap: 16px;
}

.modal-days {
  padding: 4px 8px;
  border-radius: 22px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
  cursor: pointer;
}
.modal-days-active {
  padding: 4px 8px;
  border-radius: 22px;
  border: solid 1px #33c2b9;
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
  cursor: pointer;
}

/* .modal-days:nth-child(2) {
  border: solid 1px #33c2b9;
} */

.modal-flex-col {
  display: flex;
  flex-direction: column;
  gap: 19px;
  width: 100%;
}

.straight-img {
  width: 10px;
  height: 100px;
}

.line-pic {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.disabled {
  cursor: not-allowed;
}
.modal-estimated {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 120px;
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}
.modal-right-main-flex {
  display: flex;
  gap: 6px;
}

.modal-input {
  position: relative;
  margin-bottom: 10px;
}

.modal-left-foot {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-right-foot {
  /* border: 1px solid rgb(36, 207, 13); */
  display: flex;
  flex-direction: column;
}

.modal-right-btn {
  gap: 10px;
  padding: 14px 20px;
  border-radius: 6px;
  margin-top: 5px;
  background-color: #33c2b9;
}

.modal-left-title {
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
}

.modal-span1 {
  position: absolute;
  right: 46px;
  top: 18px;
  font-family: "Satoshi", sans-serif;
  font-size: 13px;
  letter-spacing: normal;
  color: #111827;
}

.modal-span2 {
  position: absolute;
  right: 11px;
  top: 18px;
  color: #33c2b9;
  font-family: "Satoshi", sans-serif;
  font-size: 13px;
  letter-spacing: normal;
  cursor: pointer;
}

.modal-input-num {
  border: none;
  outline: none;
  background-color: #f5f6f8;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
}

.modal-right-btn {
  padding: 14px 20px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff !important;
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: #33c2b9;
}

/* Modal styles - End*/

.stake-flexi {
  font-family: Bitter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
  margin-left: 8px;
}

/* Asset component styles */

.asset_section {
  padding: 80px 19px 80px 0px;
  background-color: #fff;
}
.asset_section.settings_main {
  height: 100vh;
}

.asset_title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin-top: 25.5px;
  margin-bottom: 24px;
}
.identify-title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 24px;
}

.border--b_line {
  padding: 0px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 24px;
}

.esti-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: "Satoshi", sans-serif;
  padding: 24px;
  border-radius: 12px;
  background-color: #f7f7f7;
  margin-bottom: 24px;
}
.esti-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: left;
  color: #111827;
  font-family: "Satoshi", sans-serif !important;
}
.esti-usdt {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
  text-align: left;
  color: #111827;
}

.asset-usdt-scroll {
  overflow: scroll;
}

.esti-span {
  font-size: 14px;
  color: rgba(17, 24, 39, 0.8);
}

.dark .esti-span {
  color: #fff;
}

.depo-transaction i {
  color: #33c2b9;
}

.esti-num {
  font-size: 16px;
  letter-spacing: 0.32px;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
}

.asset-btn-wrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
  cursor: pointer;
}

.asset-btn {
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 6px;
  border: solid 1px #33c2b9;
  color: #33c2b9;
  outline: none;
  background: #fff;
}
/* .asset-btn:first-child {
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 6px;
  background-color: #33c2b9;
  color: white;
  border: none;
  outline: none;
} */
.asset-action-btn {
  padding: 4px 12px;
  border-radius: 6px;
  background-color: #f5f6f8;
  border: none;
  outline: none;
  color: #111827 !important;
}
.history-lists {
  border-bottom: 1px solid #e7e7e7;
  padding: 0px;
  display: flex;
  gap: 24px;
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 31px;
  overflow-x: scroll;
}

.history-links {
  color: #888b93;
  text-wrap: nowrap;
}

.history-links:hover {
  color: #33c2b9 !important;

}

.history-links.active {
  color: #33c2b9 !important;
  padding-bottom: 7px;
  border-bottom: 2px solid #33c2b9 !important;
}

.buy-rewards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 8px;
}

.reward-title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
}

/* Settings Component Styles */

.settings {
  padding: 20px 0;
  background-color: #fff;
}

.settings_head {
  padding: 0px 19.5px 31.8px 19.5px;
  border-bottom: solid 1px rgba(208, 208, 208, 0.5);
}

.setting-lang {
  padding: 19.5px 19.5px 19.5px;
  border-bottom: solid 1px rgba(208, 208, 208, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-title {
  font-family: "Satoshi", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: left;
  color: #111827;
}

.set-content {
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  letter-spacing: 0.28px;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
}

.set-english {
  display: flex;
}

.width-50 {
  width: 90%;
}

.set-icon {
  margin-left: 8px;
  font-size: 12px;
}

.fa-caret-down {
  color: #888b93;
}

.toggle-check {
  display: none;
}

.toggleBtn {
  width: 36px;
  height: 20px;
  border-radius: 90px;
  background-color: #33c2b9;
  cursor: pointer;
  position: relative;
}

.toggleBtn::before {
  position: absolute;
  content: "";
  background-image: url("./assets/Day.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  top: 2px;
  background-color: #fff;
  border-radius: 100%;
  left: 2px;
  transition: 0.5s;
}

.toggle-check:checked + .toggleBtn {
  background-color: #000; /* Change to your preferred dark theme color */
}

.toggle-check:checked + .toggleBtn::before {
  left: 18px; /* Move the toggle to the right */
  background-image: url("./assets/night.png"); /* Use your night image path */
}

.toggle-img {
  width: 16px;
  height: 16px;
}

input:checked + .toggleBtn::before {
  transform: translateX(100%);
}

.toggle-notification {
  width: 36px;
  height: 20px;
  border-radius: 90px;
  background-color: #33c2b9;
  cursor: pointer;
  position: relative;
}

.toggle-notification::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 2px;
  background-color: #ece6ec;
  border-radius: 100%;
  left: 2px;
  transition: 0.5s;
}

.toggle-notification2 {
  width: 36px;
  height: 20px;
  border-radius: 90px;
  background-color: #ece6ec; /* Background color when off (white) */
  cursor: pointer;
  position: relative;
  transition: background-color 0.5s ease; /* Smooth transition for background color */
}

/* Styling for the knob (circle) */
.toggle-notification2::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 2px;
  background-color: #33c2b9; /* Knob color when off (blue) */
  border-radius: 100%;
  left: 2px; /* Initial position of the knob */
  transition: left 0.5s ease, background-color 0.5s ease; /* Smooth transition when moving */
}

/* When checked (true/on), change background and knob color */
.toggle-notification2.on {
  background-color: #33c2b9; /* Background color when on (blue) */
}

.toggle-notification2.on::before {
  left: 18px; /* Moves knob to the right */
  background-color: #fff; /* Knob color when on (white) */
}

input:checked + .toggle-notification::before {
  transform: translateX(16px);
}

.phone_input {
  text-indent: 53px;
}

.select-business {
  position: relative;
}

.select-business > .set-icon {
  position: absolute;
  right: 10px;
  top: 25px;
}

.set-phoneIcon::after {
  content: " | ";
  margin-left: 4px;
}

.select-business > .set-phoneIcon {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 5px;
  left: 13px;
  top: 25px;
}

.set-phoneIcon > i {
  font-size: 10px;
}

.set-phone-no {
  color: #111827;
}
.cener_parallax_img {
  position: relative;
  overflow: hidden;
  top: -550px;
}
.information_end {
  height: 100vh !important;
}
.reqistered_address {
  width: 75%;
}
.regist_address_input {
  width: 200% !important;
}
.city_content {
  width: 100%;
}
.add_kyp_contents {
  display: flex;
  margin-top: 20px;
  gap: 12px;
}
.delete_icons {
  display: flex;
  align-items: center;
  gap: 10px;
}
.delete_icon {
  display: flex;
  /* align-items: center; */
  gap: 10px;
  justify-content: space-between;
  margin-top: 24px;
  cursor: pointer;
}
.beneficial_ownership {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.down_arrow i {
  font-size: 20px;
  color: #33c2b9;
}

.border-rg {
  border-right: 1px solid #e7e7e7;
  padding-right: 20px;
}

.dark .border-rg {
  border-right: 1px solid rgba(208, 208, 208, 0.5);
}

.dark .faq-title {
  color: #fff;
  padding-bottom: 20px;
}

.market-title-mt {
  margin-top: 30px;
}

/* media query - min-width */

@media (min-width: 768px) {
  .table-cont table > thead > tr > th {
    padding: 23.8px 0;
    align-content: center;
  }

  .table-cont table > tbody > tr > td {
    padding: 23.8px 0;
    align-content: center;
    border: none;
  }

  .table-cont {
    padding: 24px 24px 24px;
  }
  .opt-title {
    margin: 30px 0;
  }
  .opt-select {
    padding: 8px 100px 8px 9px;
  }
}

@media (min-width: 992px) {
  .staking-rewards {
    margin-top: 0px;
  }
  .staking-hero {
    padding: 8 0px 0;
  }
  .staking-hero {
    background-image: url("./assets/staking-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .stake-wrapper {
    margin-top: 40px;
  }
  .stake-para {
    width: 50%;
  }
  .width-50 {
    width: 100%;
  }
}

/* max-width */

@media (max-width: 1399px) {
  .asset_section {
    padding: 80px 19px 119px 30px;
  }
  header.scrolled .MuiAppBar-colorPrimary {
    justify-content: center;
  }
  .header-toggle-cont {
    padding: 3px;
    margin-left: 6px;
  }
  .header-toggle-cont .pro_btn {
    padding: 5px 7px;
  }
  .MuiToolbar-regular {
    gap: 28px;
  }
  /* .crypto_platform_paralax {
    display: flex;
  } */
}
@media (max-width: 1199px) {
  .asset_section {
    padding: 80px 19px 80px 60px;
  }
}

@media (max-width: 1024px) {
  .asset_section {
    padding: 80px 0px 80px 30px;
  }
}

@media (max-width: 768px) {
  .modals {
    width: 500px !important;
    height: 513px !important;
    border-radius: 12px !important;
    overflow: scroll;
  }
  .cross_circle {
    position: absolute;
    top: 25px;
    right: 20px;
  }
}
@media (max-width: 991px) {
  .asset_section {
    padding: 80px 19px 119px 220px;
  }
  .stake-head > th.history-tab-pl {
    padding-left: 93px !important;
  }
  .deposit.border-rg.mt-5 {
    border: none;
  }
}
@media (max-width: 991px) {
  .asset_section {
    padding: 80px 0px 119px 0px;
  }
  .border-rg {
    border: none;
    padding: 0px 10px;
  }
}

@media (max-width: 480px) {
  .modals_support {
    width: 95% !important;
  }
}

@media (max-width: 482px) {
  .asset_section {
    padding: 10px 0px 119px 0px;
  }
  .Verification.assets_main.kyc_page_main {
    padding: 0px;
  }
}

@media (max-width: 425px) {
  .settings_head {
    padding: 0px 19.5px 20px 19.5px;
  }
  .modals {
    width: 400px !important;
    height: 513px !important;
  }
  .esti-usdt {
    font-size: 22px;
  }
  .esti-title {
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .modals {
    width: 340px !important;
    height: 513px !important;
  }
  .esti-usdt {
    font-size: 18px;
  }
  .MuiToolbar-regular {
    gap: 12px;
  }
  .asset-btn-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 320px) {
  .modals {
    width: 300px !important;
    height: 513px !important;
  }
}

.dark .staking-hero {
  background-image: url("./assets/staking-bg-dark.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.dark .staking-hero.refer-hero {
  padding: 80px 0px 0px 0px;
  background-image: url("./assets/referral-bg-dark.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* deposit important notes */

.deposit-imp-notes {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
}

.imp-notes-title {
  display: flex;
  align-items: center;
  gap: 10px;
  display: flex;
  align-items: center;
}

.imp-notes-title p {
  font-family: "Satoshi", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.28px;
  color: #111827;
}

.deposit-imp-icon {
  width: 15px;
  height: 15px;
}

.imp-notes-content {
  /* border: 1px solid grey; */
  /* margin: 19px 0; */
  margin-top: 24px;
}

.imp-notes-content h6 {
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: #111827;
}

.imp-notes-content p {
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 1.43;
  letter-spacing: 0.14px;
  color: #111827;
}

.dark .deposit-imp-notes {
  border: 1px solid rgba(208, 208, 208, 0.5);
  background-color: #151717;
}

.dark .imp-notes-title p {
  color: #fff;
}

.dark .imp-notes-content h6 {
  color: #fff;
}

.dark .imp-notes-content p {
  color: #cecece;
}

.terms-container h5 {
  font-family: "Satoshi", sans-serif !important;
  color: #111827;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
}

.terms-container h6 {
  font-family: "Satoshi", sans-serif !important;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #111827;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.terms-container p {
  font-family: "Satoshi", sans-serif !important;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 300;
  color: #111827;
  font-size: 15px;
  width: 93%;
  text-align: justify;
  line-height: 1.8;
}

.terms-container h4 {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #111827;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-wrap;
}

.terms-container ul li {
  padding: 5px 0;
  font-family: "Satoshi", sans-serif !important;
  font-weight: 300;
  color: #111827;
  font-size: 15px;
  line-height: 1.8;
  width: 93%;
  text-align: justify;
}

.policy-sub-heading {
  font-family: "Satoshi", sans-serif !important;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #111827;
  font-size: 18px;
  line-height: 1.8;
  white-space: pre-wrap;
  text-align: justify;
}

.policy-bold {
  /* font-weight: 400; */
  color: #111827;
  line-height: 1.8;
}

li li {
  list-style-type: circle !important;
}

.terms-address {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.terms-email {
  font-family: "Satoshi", sans-serif !important;
  font-weight: 300;
  color: #111827;
  font-size: 15px;
  width: 93%;
  text-align: justify;
  line-height: 1.8;
}

.policy-terms-ad {
  padding-top: 17px !important;
}

.dark .terms-container h5 {
  color: #fff;
}

.dark .terms-container h6 {
  color: #eaecef;
}

.dark .terms-container p {
  color: #eaecef;
}

.dark .terms-container h4 {
  color: #eaecef;
}

.dark .terms-container ul li {
  color: #eaecef;
}

.dark .policy-sub-heading {
  color: #eaecef;
}

.dark .policy-bold {
  color: #fff;
}

.dark .terms-email {
  color: #eaecef;
}

@media (max-width: 1024px) {
  .policy-terms-ad {
    padding-top: 0px !important;
  }
}

@media (max-width: 480px) {
  .terms-container {
    margin-top: 20px;
  }
  /* .form_div.depo-qr-input {
    padding: 0px;
  } */
  .dep-qr {
    margin-top: 30px;
  }
  .head-pt {
    padding-top: 0px;
  }
  .dark .head-pt {
    padding-top: 34px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap");
@import url(https://db.onlinewebfonts.com/c/7bec814954d059a7e7dae047285eedbc?family=Satoshi+Medium);

body {
  font-family: "Bitter", serif !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Bitter", serif !important;
}
.mar-lft-ryt {
  margin: 0px 15px;
}
.txt-primary {
  color: #33c2b9 !important;
}
.font-bitter {
  font-family: Bitter !important;
}

.secuirty_box_content::-webkit-scrollbar,
.user_id_card::-webkit-scrollbar,
.stake-price::-webkit-scrollbar,
.history-lists::-webkit-scrollbar,
.form_div .add_box .address::-webkit-scrollbar {
  display: none;
}

.asset-usdt-scroll::-webkit-scrollbar {
  display: none;
}

.errorcss {
  color: #ff0000d6 !important;
  font-family: "Satoshi" !important;
  font-size: 13px !important;
  /* border: 1px solid grey; */
  margin-top: 5px !important;
  margin-bottom: 14px !important;
}

.kycadddes {
  font-style: initial;
  padding: 8px;
}
.stake-head.withdraw-head > th {
  padding: 10px 22px 10px 32px !important;
  color: rgba(17, 24, 39, 0.7);
  font-weight: 400;
}

.withdraw-errcss {
  text-align: start !important;
}

.dark input::placeholder {
  color: #757575 !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: #33c2b9;
  color: #ffff !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected:hover {
  background: #33c2b9 !important;
}

.ui.dropdown .menu .item:hover {
  background: #33c2b9 !important;
  color: #ffff !important;
}

.dark .ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: rgba(0, 0, 0, 0.03);
  color: #ffff !important;
}

.dark .ui.dropdown .menu .selected.item,
.ui.dropdown.selected:hover {
  background: #252629 !important;
}

.dark .ui.dropdown .menu .item:hover {
  background: #252629 !important;
  color: #000 !important;
}

.dark .ui.selection.dropdown .menu > .item {
  border-top: none;
}

.dark.ui.dropdown .menu > .item {
  color: #ffff !important;
}

.pad {
  margin-top: 5px;
}

.reg_wid_short {
  width: 100% !important;
}

.align-items-start-flex {
  align-items: start !important;
  display: flex;
  flex-direction: column;
}

.reg-row {
  justify-content: space-around;
}

.dark .dark-text-white {
  color: #fff;
}

.fidex_landing_main {
  background: #fff;
}
.fidex_hero_section {
  padding: 100px 0;
}
.hero_section_content h1 {
  color: #111827;
  font-size: 40px;
  font-weight: 600;
}
.hero_section_content p {
  color: #111827;
  font-family: "Satoshi";
  font-size: 18px;
  margin-top: 25px;
  width: 95%;
}
.kycallignment {
  margin-bottom: 10px !important;
}

.lets-try-btn {
  margin-top: 12px;
  padding: 10px 0px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  color: #bec0c3;
  border: none;
  font-family: "Satoshi";
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: default !important;
}
.loadercss2 {
  display: flex;
  justify-content: center;
}

.kycrequire {
  display: flex;
  justify-content: center;
  padding: 51px 0px;
}
.get_started_btn {
  margin-top: 12px;
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  font-family: "Satoshi";
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.get_started_btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #155a59;
  transition: left 0.3s ease-in-out;
  z-index: -1;
  color: #fff;
}

.get_started_btn:hover::before,
.get_started_btn:focus::before {
  left: 0;
}

.get_started_btn:hover,
.get_started_btn:focus {
  color: #fff;
  border-color: #155a59;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: none;
}
.onlycolor {
  color: #33c2b9 !important;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background-color: transparent !important;
}

.hero_section_banner img {
  width: 100%;
}

.fidex_hero_section .row {
  display: flex;
  align-items: center;
}

.secrets_crypto_title {
  margin-bottom: 80px;
}

.secrets_crypto_title h2 {
  color: #fff;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
}

.secrets_crypto_section {
  padding-bottom: 40px;
  padding-top: 0;
}

.secrets_crypto_content {
  padding: 40px 39px 47px 40px;
  border-radius: 20px;
  background-color: #1f2937;
}
.secrets_crypto_title p {
  color: #fff;
  font-family: "Satoshi";
  text-align: center;
  font-size: 18px;
}

.secrets_crypto_create_icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.secrets-icon-wrapper {
  width: 24px;
  height: 24px;
}

.secrets_crypto-title {
  margin: 0px;
}

.secrets-icon-wrapper > .secrets-icon {
  width: 100%;
  height: 100%;
}

.secrets_crypto_create_icon h4 {
  font-family: "Satoshi" !important;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
  margin-left: 5px;
}
.secrets_crypto_create_icon img {
  width: 35px;
}
.secrets_crypto_create p {
  font-family: "Satoshi" !important;
  font-size: 16px;
  color: #fff;
  margin-top: 18px;
  opacity: 0.8;
  font-weight: 300;
  height: 50px;
}

.secrets_crypto_create {
  padding: 24px 16px 24px 24px;
  background: url("./assets/creat_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}

.bg_overflow {
  border-radius: 12px;
  /* padding: 0; */
  overflow: hidden !important;
  /* border: 1px solid white; */
}

.bg_overflow .secrets_crypto_create {
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.mar-top-create,
.mar-top-deposit {
  margin-top: 48px !important;
}

.mar-top-trade {
  margin-top: 48px !important;
}

.learn_more {
  display: flex;
  align-items: end;
  margin-top: auto;
  /* border: 1px solid white; */
}

.secrets_crypto_create.trade_card {
  background: url("./assets/trade-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}

.secrets_crypto_create.deposit_card {
  background: url("./assets/deposit_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}

.fast_tranSsaction_card img {
  width: 80px;
  margin-bottom: 20px;
}

.advance_trade_section {
  padding: 80px 0;
}
.advance_trade_title {
  font-size: 28px;
  color: #111827;
  font-weight: 600;
  margin-bottom: 50.8px;
}

.fast_tranSsaction_card {
  padding: 24px 40px;
  border-radius: 12px;
  background-color: #f5f6f8;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 320px !important;
}

.fast-trans-title {
  margin: 20px 0px;
}

.fast-transImg-wrapper-one {
  width: 73.4px;
  height: 70px;
}

.fast-transImg-wrapper-two {
  width: 74.2px;
  height: 70px;
}

.fast-transImg-wrapper-three {
  width: 63px;
  height: 63px;
}

.fast-transImg-wrapper-one > .fast-trans-img {
  width: 100%;
  height: 100%;
}

.fast-transImg-wrapper-two > .fast-trans-img {
  width: 100%;
  height: 100%;
}

.fast-transImg-wrapper-three > .fast-trans-img {
  width: 100%;
  height: 100%;
}

.fast_tranSsaction_card h4 {
  color: #111827;
  font-size: 20px;
  font-weight: 600;
}

.fast_tranSsaction_card p {
  font-family: "Satoshi";
  font-size: 18px;
  color: #555b66;
  opacity: 0.8;
  font-weight: 300 !important;
  margin-bottom: 8px;
  min-height: 77px;
}

.trade-btn {
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 6px;
  color: #33c2b9;
  font-size: 16px;
  font-weight: 400;
  font-family: "Satoshi";
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: transparent;
  transition: color 0.4s ease-in-out;
  cursor: default !important;
}

.deposit_refresh {
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.trade-btn i {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.trade-btn::before {
  position: absolute;
  content: "";
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #33c2b9;
  transition: left 0.4s ease-in-out;
  z-index: -1;
}

.trade-btn:hover::before {
  left: 0;
}

.trade-btn:hover {
  color: #fff;
  border-color: #33c2b9;
}

.advance_trading_section {
  padding-top: 60px;
  padding-bottom: 0;
}

.advance_trading_section_content {
  padding: 40px 40px 80px 24px;
  border-radius: 20px;
  background-color: #1f2937;
  overflow: hidden;
}

.crypto_platform_paralax {
  max-height: 400px;
  position: relative;
  top: -250px;
}

.discover_advance_content h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: "Satoshi" !important;
}

.discover_advance_content p {
  color: #fff;
  font-size: 18px;
  font-family: "Satoshi";
  font-weight: 300;
  opacity: 0.8;
  width: 93%;
}
.advance_trading_section_content .row {
  align-items: center;
}
.discover_advance_content h2 {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  width: 90%;
}
.supported_currencies {
  padding: 80px 120px 80px 80px;
  border-radius: 20px;
  background-color: #daf1f1;
  overflow: hidden;
}
.buy_chicken_section .row {
  justify-content: center;
}
.buy_chicken_section {
  background: #1f2937;
  border-radius: 10px;
  overflow: hidden;
}
.supported_banner {
  display: flex;
  justify-content: center;
}
.supported_currencies .row {
  align-items: center;
}
.supported_currencies_content h4 {
  font-family: "Satoshi" !important;
  font-size: 18px;
  font-weight: 500;
  color: #111827;
  margin: 0px;
}
.supported_currencies_content h2 {
  font-size: 32px;
  font-weight: 600;
  color: #111827;
  margin-top: 8px;
  margin-bottom: 12px;
}
.supported_currencies_content p {
  font-family: "Satoshi";
  font-size: 18px;
  width: 84%;
  color: #111827;
}
.supported_currencies_content button {
  padding: 10px 20px;
  border-radius: 6px;
  border: solid 1px #33c2b9;
  color: #33c2b9;
  background: transparent;
  font-family: "Satoshi Medium";
}
.keep_trade_section {
  padding: 80px 0;
  margin-top: 40px;
}
.play_apps {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.keep_trade_banner {
  position: relative;
}
.keep_trade_bg {
  position: absolute;
  left: -15%;
  z-index: 1;
  top: -44%;
  /* border: 1px solid black; */
}

.dark .keep_trade_mobile_banner.grid-light-visible {
  display: none;
}

.keep_trade_mobile_banner.grid-dark-visible {
  display: none;
}

.dark .keep_trade_mobile_banner.grid-dark-visible {
  display: block;
}

.bonus_btns button {
  padding: 10px 12px;
  border-radius: 29px;
  border: solid 1px #33c2b9;
  background: transparent;
  color: #33c2b9;
  cursor: pointer;
  font-family: "satoshi";
  cursor: default !important;
}

.bonus_btns {
  display: flex;
  gap: 15px;
}
.refer_earn_section {
  padding: 80px 0 0;
  background-color: #1f2937;
  padding-bottom: 40px;
  background: url("./assets/refer_earn_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
}
.refer_earn_section_content h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.refer_earn_section_content p {
  font-family: "Satoshi";
  font-size: 18px;
  color: #fff;
  opacity: 0.8;
  font-weight: 300;
}

.refer_earn_img {
  display: flex;
  justify-content: center;
}
.bonus_btns button:hover {
  background: #33c2b9 !important;
  color: #fff !important;
}
.keep_trade_content h2 {
  color: #111827;
  font-size: 32px;
  font-weight: 600;
}
.keep_trade_content p {
  color: rgba(17, 24, 39, 0.7);
  font-family: "Satoshi";
  font-size: 18px;
  font-weight: 300;
  width: 85%;
}

.bor-bottom {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 80px;
}

.footer_section {
  padding: 40px 0;
}
.footer_content img {
  width: 150px;
  margin-bottom: 25px;
}

.copyrights {
  text-align: center;
  margin: 20px 0;
  color: #111827;
  font-family: "Satoshi";
  font-size: 12px;
}
.footer_content p {
  font-family: "Satoshi";
  font-size: 16px;
}
.services_content {
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-around;
}
.services li {
  list-style: none;
}
.country-content h4 {
  text-decoration: none;
  color: #111827;
  font-size: 14px;
  font-family: "Satoshi" !important;
  font-weight: 500;
}
.services li a {
  text-decoration: none;
  color: #111827;
  font-size: 14px;
  font-family: "Satoshi";
  font-weight: 500;
}
.services img {
  width: 24px !important;
  margin-right: 20px;
}
.icon {
  width: 24px;
  margin-right: 20px;
  color: #111827;
}
.services h3 {
  font-family: "Satoshi";
  font-size: 18px;
  font-weight: 500;
  color: #111827;
}

.dark .css-1lz3yih-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
  color: #fff !important;
  cursor: pointer;
}

/* Default (light mode) pagination and arrow styles */
.MuiPaginationItem-root {
  color: grey; /* Unselected pagination item color */
}
.MuiPaginationItem-previous,
.MuiPaginationItem-next {
  color: grey !important; /* Unselected arrow color */
}

/* Dark mode pagination and arrow styles */
.dark .MuiPaginationItem-root {
  color: grey; /* Unselected pagination item color for dark mode */
}
.dark .MuiPaginationItem-previous,
.dark .MuiPaginationItem-next {
  color: grey; /* Unselected arrow color for dark mode */
}

/* Selected pagination item styles */
.MuiPaginationItem-root.Mui-selected {
  background-color: #33c2b9; /* Selected background color */
  color: white; /* Selected text color */
}

.dark .MuiPaginationItem-root.Mui-selected {
  background-color: #33c2b9; /* Selected background color for dark mode */
  color: white; /* Selected text color for dark mode */
}

/*Dashboard */
.pagination {
  display: flex;
  justify-content: center;
  position: sticky;
  left: 0;
}

.dash_table_content td {
  border: none !important;
}
.dash_table_content {
  margin-bottom: 80px;
}
.dash_table_content tbody tr {
  border-bottom: 1px solid #e7e7e7 !important;
  /* border: none !important; */
}
.dashboard_main {
  background: #f5f6f8;
  /* height: 100vh; */
}
.dashboard_main.settings_main {
  height: 100vh;
}
.dashboard_main .row {
  background: #fff;
}

/*
      old

.dashboard_pages_list {
  padding-top: 80px;
  border-right: 1px solid rgba(208, 208, 208, 0.5);
  height: 100%;
  position: fixed;
  width: 192px;
} */

.dashboard_pages_list {
  padding-top: 80px;
  border-right: 1px solid rgba(208, 208, 208, 0.5);
  height: 100%;
  position: fixed;
  width: inherit;
  /* padding-right: 55px; */
  padding-left: 24px;
}

.pad-lft {
  padding-left: 0px !important;
}

.dashboard_title {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 16px 19px 10px 14px;
  width: 100% !important;
}

.dashboard_title h3 {
  margin-top: 0;
  color: #414652;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  font-family: "Satoshi" !important;
}
.dashboard_title img {
  width: 25px;
}
.dashboard_title.active-link {
  border-left: solid 4px #33c2b9;
  padding-left: 10px;
  background-color: #f7f7f7;
}

/* .dashboard_title.active-link h3 {
  color: #ffc630 !important;
} */

/* .dashboard_title.active-link img {
  filter: brightness(0) saturate(100%) invert(78%) sepia(73%) saturate(740%)
    hue-rotate(331deg) brightness(106%) contrast(106%);
} */

.swap_icon img {
  width: 30px;
  cursor: pointer;
}
.swap_icon {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.dash_user_name {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.dash_user_name img {
  width: 30px;
}

.dark .dash_user_name img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(504%)
    hue-rotate(283deg) brightness(114%) contrast(100%);
}

.dash_user_name h3 {
  color: #111827;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
  font-family: "Bitter" !important;
}

.tab-mar-r-10 {
  margin-right: 6px !important;
}

.low-clr {
  color: #f84960;
}

.medium-clr {
  color: #ffaa5f;
}

.high-clr {
  color: #2ebd85;
}

.verified_low_btn .verify_medium,
.secuirty_box_title .verify_medium {
  padding: 4px 8px;
  border-radius: 60px;
  background-color: rgb(248, 192, 73, 0.1);
  width: fit-content;
  color: #ffaa5f;
  font-size: 12px;
  height: fit-content;
  font-family: "satoshi";
}
.verified_low_btn .verify_fail,
.secuirty_box_title .verify_fail {
  padding: 4px 8px;
  border-radius: 60px;
  background-color: rgba(248, 73, 96, 0.1);
  width: -moz-fit-content;
  width: fit-content;
  color: #f84960;
  font-size: 12px;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Satoshi";
}
.verified_low_btn .verify_success,
.secuirty_box_title .verify_success {
  padding: 4px 8px;
  border-radius: 60px;
  background-color: rgb(73, 248, 84, 0.1);
  width: -moz-fit-content;
  width: fit-content;
  color: #2ebd85;
  font-size: 12px;
  height: fit-content;
  font-family: "Satoshi";
}

.verified_low_btn .verify_success i,
.secuirty_box_title .verify_success i {
  margin-right: 2px;
}

.verified_low_btn .verify_medium i,
.secuirty_box_title .verify_medium i {
  margin-right: 2px;
}

.verified_low_btn .verify_fail i,
.secuirty_box_title .verify_fail i {
  margin-right: 2px;
}

.verified_low_btn {
  display: flex;
  gap: 12px;
  margin-top: 6px;
}
.user_id_card {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  padding: 24px;
  border-radius: 12px;
  background-color: #f7f7f7;
  overflow: hidden;
  overflow-x: scroll;
  flex-wrap: nowrap;
  text-wrap: nowrap;
}
.uid_content h4 {
  font-family: "Satoshi" !important;
  font-size: 13px;
  color: #3f4551 !important;
  /* opacity: 0.8; */
  font-weight: 200;
}

.uid_content p {
  font-family: "Satoshi" !important;
  font-size: 14px;
  font-weight: 500;
  color: #111827;
}

.secuirty_box_title p {
  padding: 4px 8px;
  border-radius: 60px;
  background-color: rgba(248, 73, 96, 0.1);
  width: -moz-fit-content;
  width: fit-content;
  color: #f84960;
  font-size: 13px;
  font-family: "Satoshi" !important;
}

.dashboard_content {
  padding-top: 100px;
  /*  padding: 80px 19px 119px 0px; */
  /* border-bottom: 1px solid rgba(208, 208, 208, 0.5); */
  /* padding-bottom: 40px; */
  /* padding-right: 24px; */
}

.secuirty_box_title {
  margin-top: 20px;
}
.secuirty_box_title {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.referral_sec_box {
  margin-top: 20px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
}

.referral_sec_box p {
  padding: 4px 8px;
  border-radius: 60px;
  background-color: rgba(248, 73, 96, 0.1);
  width: -moz-fit-content;
  width: fit-content;
  color: #f84960;
  font-size: 13px;
  font-family: "Satoshi" !important;
}

.secuirty_box {
  /* padding: 0 20px; */
  padding-right: 20px;
  border-right: 1px solid rgba(208, 208, 208, 0.5);
  /* border: 1px solid black; */
}
.secuirty_box_content {
  padding: 24px 21px;
  border-radius: 12px;
  background-color: #f7f7f7;
  display: flex;
  gap: 8px;
  overflow: scroll;
}

.secuirty_box_content2 {
  padding: 24px 21px;
  border-radius: 12px;
  background-color: #f7f7f7;
  display: flex;
  gap: 8px;
  /* overflow: scroll; */
}
.secuirty_box_title h3 {
  font-size: 18px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 0;
}
.border_none {
  border: none !important;
}
.login_asset img {
  width: 40px;
}
.login_verify_content h4 {
  font-family: "Satoshi" !important;
  font-size: 13px;
  color: #3f4551;
}
.login_verify_content p {
  font-family: "Satoshi Medium";
  font-size: 14px;
  color: #111827;
}
.border-bottom {
  border-bottom: 1px solid rgba(208, 208, 208, 0.5) !important;
  padding: 34px 0;
}
.bln_view {
  color: #33c2b9 !important;
  background: transparent !important;
}
.security_settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  font-family: "Satoshi";
}

.dark .security_settings {
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.low_line {
  display: flex;
  align-items: center;
  gap: 12px;
}

.low_line .verify_fail {
  font-family: "Satoshi";
  font-size: 14px;
  margin: 0px;
  color: #f84960;
}

.low_line i {
  font-size: 20px;
}

.low_line .verify_medium {
  font-family: "Satoshi";
  font-size: 14px;
  color: #ffaa5f;
  margin: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.low_line .verify_success {
  font-family: "Satoshi";
  font-size: 14px;
  margin: 0px;
  color: #2ebd85;
}

.secu_img {
  width: 78px;
}
.deposit_blc_content {
  flex-direction: column;
}
.deposit_withdraw_btns .deposit_btn {
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  border: none;
  width: 50%;
  color: #fff;
  font-family: "Satoshi";
  font-size: 16px;
}
.deposit_withdraw_btns .withdraw_btn {
  padding: 10px 20px;
  border-radius: 6px;
  background-color: rgba(51, 194, 185, 0.1);
  border: none;
  width: 50%;
  color: #33c2b9;
  font-family: "Satoshi";
  font-size: 16px;
}
.deposit_withdraw_btns {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}
.total_balance_amt {
  font-size: 20px !important;
  margin-bottom: 1px;
}
.currecydash {
  gap: 10px;
  align-items: center !important;
  /* margin-bottom: -3px; */
}
.currecydash2 {
  background-color: #f7f7f7 !important;
  border: none !important;
}
.dark .currecydash2 {
  background-color: #000 !important;
  border: none !important;
}
.usd_text {
  font-size: 14px !important;
}
.action_btn {
  padding: 4px 12px;
  border-radius: 6px;
  background-color: #33c2b9;
  border: none;
  color: #fff;
}
.table_action {
  display: flex;
  justify-content: end;
}
.dash_assets {
  margin: 25px 0;
}
.table_center_text {
  text-align: center !important;
}
.table_center_frs {
  text-align: start !important;
}
.table_center_last {
  text-align: end !important;
  cursor: pointer;
}

.pad-left-23 {
  padding-left: 0px !important;
}
.paddingreward {
  padding: 24px 12px !important;
}

.pad-top-12px {
  padding-top: 0px !important;
}

.referral_asset img {
  width: 85px;
}
.secuirty_box_content.refer_content {
  justify-content: space-between;
  align-items: center;
}
.refer_content i,
.share_btn i {
  color: #33c2b9;
}
.share_btn {
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #f7f7f7;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.invite_text {
  font-family: "Satoshi";
  font-size: 14px;
  color: rgba(17, 24, 39, 0.8);
  margin-bottom: 10px;
}
/* Swap */

.menu_new_typr a:hover {
  color: #33c2b9 !important;
}
.convert_card {
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
}
.dashboard_content.border_none .row {
  display: flex;
  justify-content: center !important;
  height: 100vh;
}

.convert_card-wrapper {
  padding-top: 100px;
  padding-bottom: 40px;
}

.convert_card-summary {
  padding-top: 120px;
  padding-bottom: 40px;
}

.text_green {
  color: #33c2b9;
  font-size: 14px;
  font-family: "Satoshi";
}

.dark .text_green {
  color: #33c2b9 !important;
}

.span_text {
  color: #33c2b9;
  font-family: "Satoshi";
}
.table_icon i {
  color: #33c2b9;
}
.convert_title {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.convert_center_box {
  margin: 0 auto;
}
.spend_content {
  padding: 20px;
  border-radius: 12px;
  border: solid 1px #d0d0d0;
  background-color: #fff;
}

.spend_content h4 {
  font-family: "Satoshi" !important;
}

.spend_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spend_amt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swap_balance {
  font-family: "Satoshi";
  margin-left: 8px;
  margin-top: 2px;
  color: #888b93;
  font-size: 14px;
}
.swap_change_clr {
  font-family: "Satoshi";
  margin-left: 8px;
  margin-top: 2px;
  color: #888b93;
  font-size: 14px;
}
.swap_balance span {
  color: #111827;
}
.Convert_btn button {
  padding: 14px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  border: none;
  color: #fff;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;
}
.swap_main {
  height: 100vh;
}

.security_content h3 {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
}

.two_fa_heading {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin-top: 20px;
}
.security_email_content img {
  width: 45px;
  height: fit-content;
}
.security_email_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid #e7e7e7;
  /* border: 1px solid black; */
}

.dark .security_email_content {
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.security_email_item {
  display: flex;
  margin-top: 40px;
  gap: 15px;
  align-items: center;
}

.security_email_item.security-width-wrap {
  /* border: 1px solid grey; */
  width: 50%;
}

.email_id_text i {
  color: #22b477;
}
.enable_btn button {
  padding: 8px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  border: none;
  color: #fff;
}
.dark .tfabutton {
  color: #fff;
}
.disable_btn button {
  padding: 8px 20px;
  border-radius: 6px;
  background-color: #f84960;
  border: none;
  color: #000;
}
.delete_button button {
  padding: 8px 20px;
  border-radius: 6px;
  background-color: rgba(244, 94, 113, 0.2);
  border: none;
  color: #f45e71;
  font-size: 16px;
}
.security_email_content .ri-close-circle-fill {
  color: #f45e71;
  font-size: 16px;
}
.Non_fixed_nav header {
  position: relative !important;
}
.d-flex {
  display: flex !important;
  align-items: center !important;
}
.two_fa_qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.identification_section {
  padding: 80px 0;
  padding-left: 40px;
}

.identification_title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin-top: 20px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 25px;
}

.dark .identification_title {
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.deposit-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deposit--title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
}

.deposit--btn {
  border: none;
  outline: none;
  font-family: "Satoshi";
  padding: 10px;
  border-radius: 9px;
  background-color: transparent;
  color: #33c2b9;
  font-size: 14px;
  font-weight: 400;
}

.deposit--btn i {
  font-size: 14px;
  margin-left: 6px;
}

.before_kyc_depo {
  height: 100%;
  width: 100%;
}
.withdraw-p-l-24 {
  padding-left: 24px;
  padding-right: 28px;
}

.deposit-identity-box {
  border-radius: 12px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  padding: 24px 24px 0 0;
}

.dark .deposit-identity-box {
  border: 1px solid rgba(208, 208, 208, 0.5);
  background-color: #151717;
}

.dep-kyc {
  padding-left: 24px;
}

.dep-kyc p {
  font-size: 14px;
  color: #cececf;
  padding-left: 24px;
  margin-top: 9px;
  margin-bottom: 23px;
  letter-spacing: 0.2px;
  font-family: "Satoshi";
  font-weight: 300;
  line-height: 1.43;
  letter-spacing: 0.14px;
  color: #111827;
}

.dark .dep-kyc p {
  color: #cecece;
}

.dep-kyc-head {
  display: flex;
  gap: 9px;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dep-kyc-head h6 {
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 500;
  color: #111827;
}

.dark .dep-kyc-head h6 {
  color: #fff;
}

.dep-kyc-btn {
  padding: 14px 20px;
  height: 50px;
  border-radius: 6px;
  background-color: #33c2b9;
  border: none;
  outline: none;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.text-primary {
  color: #33c2b9 !important;
}

.verify_btn {
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  font-family: "Satoshi";
}
.verify_btn a {
  text-decoration: none;
}
.verify_btn a:hover {
  color: #fff !important;
}
.standard_verify_box {
  padding: 17px 49px 17px 24px;
  border-radius: 12px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
}
.standard_verify_content {
  background: #f7f7f7;
  margin: 0 !important;
}

.standard_verify_content-flex {
  display: flex;
  gap: 5px;
  align-items: center;
}

.standard_verify_img img {
  width: 120px;
}
.individual_title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin-top: 20px;
}

.dark .individual_title {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 20px;
}

.identification_features {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-radius: 12px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  margin-top: 25px;
  font-family: "Satoshi";
}
.unverified {
  text-align: center;
}
.standard_verify_content img {
  width: 50px;
}
.features.unverified i {
  font-size: 22px;
}
.modal-header {
  border-bottom: none !important;
}
.react-tel-input .form-control {
  padding: 20px 16px !important;
  border-radius: 6px !important;
  background-color: #f5f6f8 !important;
  border: none !important;
}
.react-tel-input .flag-dropdown {
  border: none !important;
  top: 20px !important;
  padding: 0px 0px 0px 4px !important;
  margin-left: 3px !important;
}
.dark .react-tel-input .flag-dropdown:hover {
  background: #151717 !important;
}
.dark .react-tel-input .flag-dropdown.open {
  background: #151717 !important;
}
.dark .react-tel-input .flag-dropdown.open .selected-flag {
  background: #151717 !important;
}
.select_id_text {
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: 500;
  color: #000000de !important;
}
.modal_continue_btn {
  padding: 14px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
.modal_footer_text {
  font-family: "Satoshi";
  font-size: 13px;
  font-weight: 400;
  color: rgba(17, 24, 39, 0.5) !important;
  text-align: center;
}
.modal-footer {
  display: inline !important;
}
.passport img {
  width: 40px;
}
.seleted_id_type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 6px;
  border: solid 0.5px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  margin-bottom: 16px;
}
.passport {
  display: flex;
  align-items: center;
  gap: 10px;
}
.check_circle i {
  color: #33c2b9;
  font-size: 22px;
}
.modal-content {
  border-radius: 12px !important;
}
.modal_back_btn {
  padding: 14px 20px;
  background: transparent;
  color: #33c2b9;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
.first_name input {
  border: none;
  padding: 16px;
  border-radius: 4px;
  background-color: #f5f6f8;
}

.first_name_content {
  display: flex;
  gap: 50px;
}
.id_number input {
  width: 100%;
}
.id_number {
  margin-top: 12px;
}
.modal-body.personal_verify_body {
  min-height: 350px;
}
.refeferal_section {
  align-items: center;
}
.invite_text {
  font-size: 16px;
  font-weight: 500;
  color: #111827;
}
.refferal_section_bg {
  height: 19px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Bitter";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
}
.Rectangle-15 {
  width: 414px;
  height: 305px;
  flex-grow: 0;
  margin: 0 0 0 294px;
  padding: 34px 20px 22px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
}
.stake-title {
  font-size: 36px;
}
.deposit_notes {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #f5f6f8;
  font-family: "Satoshi";
  font-size: 14px;
  color: #111827;
}
.withdraw_title_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 40px;
}

.dark .withdraw_title_content {
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
}

.withdraw_title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
}
.deposit .form_div input {
  text-overflow: ellipsis;
}

.dark .fees_content h4 {
  font-family: "Satoshi";
  font-size: 14px !important;
  color: #fff;
  font-weight: 500 !important;
}
.dark .fees_content p {
  font-family: "Satoshi";
  font-size: 14px;
  color: #fff;
}
.dark .fees_content {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.fees_content h4 {
  font-family: "Satoshi";
  font-size: 14px !important;
  color: rgba(17, 24, 39, 0.8);
  font-weight: 500 !important;
}
.fees_content p {
  font-family: "Satoshi";
  font-size: 14px;
  color: #111827;
}
.fees_content {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.sumbit_btn button {
  font-family: "Satoshi";
  border: none;
  padding: 14px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  width: 98%;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.dep-drops.support_textarea {
  height: 150px;
  resize: none;
}
.trade_qr_img {
  width: 120px;
  height: auto;
}

.parallax-text {
  flex-direction: column;
  gap: 12px;
}
.buy_chicken_section {
  justify-content: center;
}
.buy_chicken_section .row {
  max-height: 380px;
}
.referral_content_box {
  padding: 20px 40px;
  border-radius: 12px;
  background-color: #f7f7f7;
  /* overflow: scroll; */
  height: 100%;
}
.referral_content_text img {
  width: 25px;
}
.referral_content_text {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.referral_content_box h4 {
  font-family: "Satoshi";
  font-size: 20px;
  font-weight: bold;
  color: #111827;
  text-align: center;
  /* overflow: scroll; */
}
.my-refferal-eraning {
  margin: 50px 0 59px;
  padding: 32px;
  border-radius: 12px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
}
.my-refferal-eraning-title.opt-title {
  margin-bottom: 40px !important;
}

.clr-junc {
  margin-top: 10px;
  font-family: "Satoshi";
  color: #111827;
  font-weight: bold;
  font-size: 18px;
}

.dark .clr-junc {
  color: #fff;
}

.refferal_code {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 16px;
  border-radius: 4px;
  background-color: #f5f6f8;
  align-items: center;
}
.refferal_code h4 {
  margin: 0px;
  color: #111827;
  font-weight: 400;
  font-size: 13px;
  font-family: "Satoshi" !important;
}

.refferal_code input {
  border: none;
  background: transparent;
  font-family: "Satoshi";
  font-size: 13px;
  color: rgba(17, 24, 39, 0.5);
  text-align: right;
  margin-right: 10px;
}

.refferal_code i {
  color: #33c2b9;
}
.invite_btn button {
  padding: 14px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  width: 100%;
}
.kyc_page_main h6 {
  margin-top: 0;
  padding-top: 50px;
}
.Verification.assets_main.kyc_page_main {
  background: #f5f6f8;
}
.kyc_information {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.nationality_dropdown {
  width: 100%;
  border: none;
  background: transparent;
  color: #9a9da4;
  font-size: 16px;
}
.nationality_dropdown {
  outline: none;
}

.heading .inf_no {
  background-color: rgba(17, 24, 39, 0.8);
  border-radius: 50%;
  margin-right: 8px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Satoshi";
  font-weight: 400;
  min-width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inf_no_6 {
  background-color: rgba(17, 24, 39, 0.8);
  border-radius: 50%;
  margin-right: 8px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Satoshi";
  font-weight: 400;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kyc_country_dropdown .css-b62m3t-container {
  width: 100% !important;
}

.kyc_country_dropdown .css-13cymwt-control {
  border: none !important;
  background: transparent;
  outline: none !important;
}

.css-b62m3t-container:focus-visible {
  outline: none !important;
}
.dropdown-button {
  outline: none; /* Removes the outline */
}
button {
  outline: none;
}
.access_act_text_btn .yes_button {
  padding: 6px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  color: #fff;
  border: none;
}
.access_act_text_btn .no_button {
  padding: 6px 20px;
  border-radius: 6px;
  background-color: transparent;
  color: #838790;
  border: none;
}

.clr-primary {
  color: #111827;
}

.dark .clr-primary {
  color: #fff;
}

.dark .kyb-accor-header {
  background-color: #151717 !important;
  color: #fff !important;
  border: none !important;
}

.dark .kyb-accordian {
  border: none !important;
}

.clr-neutral {
  color: #585d68;
}

.access_act_text_btn {
  padding: 4px 4px;
  border-radius: 5px;
  background-color: #f5f6f8;
  width: fit-content;
  margin-top: 10px;
}
.access_act_text h3 {
  font-family: "Satoshi" !important;
  font-size: 14px;
  color: #111827;
  margin-top: 20px !important;
  margin-bottom: 0px;
  font-weight: 400;
}
.access_act_text p {
  font-family: "Satoshi" !important;
  margin-top: 10px;
  text-align: left;
  color: #111827;
  font-weight: 300;
}
svg.css-tj5bde-Svg {
  color: #33c2b9;
}

.checkbox-round {
  width: 14px;
  height: 14px;
  /* background-color: white; */
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #33c2b9;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.checkbox-round-checked {
  width: 14px;
  height: 14px;
  /* background-color: white; */
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #33c2b9;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background-color: #33c2b9;
}

.check-label {
  cursor: pointer;
}

.holder_content {
  margin-top: 20px !important;
}

/* .checkbox-round:checked {
  background-color: #33c2b9;
} */

/* Hide the default checkbox */
.regular_checkbox {
  /* display: none; */
  /* opacity: 0; */
  /* position: absolute; */
  opacity: 0;
  position: relative;
  z-index: 5;
  cursor: pointer;
}

/* Create a custom checkbox appearance */
.checkbox-container {
  position: relative;
  display: inline-block;
}

.checkbox-container .custom-check {
  display: inline-block;
  width: 15px;
  height: 15px;
  /* background-color: #33c2b9; */
  border: 1px solid #33c2b9;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  top: 3px;
  margin-right: 0px;
  position: absolute;
  z-index: 0;
}
.custom-check {
  display: inline-block;
  width: 15px;
  height: 15px;
  /* background-color: #33c2b9; */
  border: 1px solid #33c2b9;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  top: 3px;
  margin-right: 0px;
  position: absolute;
  z-index: 0;
}

/* tick styles */
.checkbox-container input:checked + .custom-check::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 3px;
  width: 5px;
  height: 8px;
  /* background-color: #33c2b9; */
  border: solid #33c2b9;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: center;
}

.driver_license_content {
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 8px;
  font-family: "Satoshi" !important;
  font-size: 13px;
  color: #111827;
  font-weight: 400;
}

.driver_license_content label {
  font-family: "Satoshi";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #111827;
}

.dark .driver_license_content label {
  color: #ffffffa3;
}

.or_text {
  font-family: "Satoshi";
  font-size: 13px;
  font-weight: normal;
  color: rgba(17, 24, 39, 0.7);
}

.upload {
  padding: 24px 19px;
  border-radius: 5px;
  border: dashed 1px #33c2b9;
  background-color: rgba(51, 194, 185, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 180px;
  width: 200px;
  margin-top: 12px;
  position: relative;
}

.webcam_icon {
  width: 20px;
}

.driver_license_doc {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ri-upload-2-line {
  font-size: 24px;
  color: #33c2b9;
}
.input-groups .input-label {
  margin: 0;
  padding: 0;
  margin-top: 20px !important;
}
.upload p {
  font-family: "Satoshi" !important;
  font-size: 12px;
  color: #33c2b9;
  font-weight: 300;
}

.cur-pointer {
  cursor: pointer;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.add_compant_content {
  color: #33c2b9;
  display: flex;
  gap: 5px;
  margin-top: 12px;
}

.add_compant_content h4 {
  font-family: "Satoshi" !important;
  font-size: 14px;
  color: #33c2b9;
  font-weight: 400;
  margin-top: 0;
}
.delete_text1 {
  color: #f45e71;
  font-size: 14px !important;
  width: -moz-fit-content;
  width: fit-content;
}
.delete_text {
  color: #f45e71;
  font-size: 14px !important;
  margin-top: 15px;
  width: fit-content;
  font-family: "satoshi";
}
.input-checkboxs ul li {
  font-family: "Satoshi";
  font-size: 14px;
  color: rgba(17, 24, 39, 0.7);
  list-style: none;
  margin-top: 16px;
  display: flex;
  /* align-items: center; */
  gap: 7px;
  font-weight: 500;
}
.input-checkboxs input {
  border-radius: 4px;
  border: 1px solid #33c2b9;
}
.input-checkboxs ul {
  padding: 0;
}

/* input[type="checkbox"] {
  background-color: #33c2b9 !important;
  color: white;
} */

.css-13cymwt-control {
  outline: none !important;
  cursor: pointer !important;
}

.custom-select-stake .css-13cymwt-control {
  padding: 4px !important;
}

.custom-select-stake .css-t3ipsp-control {
  padding: 4px !important;
}

.custom-select-stake.kyc-verify .css-13cymwt-control {
  padding: 6px !important;
}

.custom-select-stake.kyc-verify .css-t3ipsp-control {
  padding: 6px !important;
}

.dark .usdc-dark-symbol {
  color: #fff;
}

.kyc-5 .css-t3ipsp-control {
  padding: 6px 4px !important;
}

input:checked ~ .checkmark {
  background-color: #33c2b9;
}
.opening_acc_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opening_acc_title h4 {
  font-family: "Satoshi" !important;
}

.opening_acc_title i {
  font-size: 25px;
}
.holder_checkbox {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 8px;
}
.holder_checkbox_content {
  margin-bottom: 30px;
  color: #585d68 !important;
  font-family: "Satoshi";
}
.next_subit_buttons {
  display: flex;
  gap: 20px;
}
.save_button {
  padding: 14px 20px !important;
  border-radius: 6px;
  background-color: rgba(51, 194, 185, 0.2) !important;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 500;
  color: #33c2b9 !important;
}
.subit_button {
  padding: 14px 20px !important;
  border-radius: 6px;
  background-color: #33c2b9 !important;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
}
.kyc_page_main.kyb_information5 {
  height: 100% !important;
}
.css-t3ipsp-control {
  box-shadow: none !important;
  box-sizing: border-box;
  background: #f5f6f8 !important;
  border: none !important;
  outline: none !important;
}
[type="checkbox"],
[type="radio"] {
  accent-color: #33c2b9;
  border-radius: 12px !important;
}
::placeholder {
  color: #b1b3b9;
  opacity: 0.8;
}
.disabled_text p {
  color: #333844;
}
.dark .disabled_text p {
  color: #ffff;
}
.verify_medium span {
  color: #ff9a46;
}
.opt-percent .fa.fa-clone {
  margin-left: 10px;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  top: 67px !important;
  position: relative;
  width: max-content;
}
.opt-select option {
  background: #f5f6f8 !important;
  color: #000 !important;
  padding: 20px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.table-action {
  text-align: center !important;
}
/*Dark Theme */
.dark .css-t3ipsp-control {
  background: #151717 !important;
  border: 1px solid #727373 !important;
}
.dark .opening_acc_title i {
  color: #fff;
}
.dark .inf_no_6 {
  background: #33c2b9;
  color: #fff;
}
.dark .opening_acc_title h4 {
  color: #ffffff;
}
.dark .holder_checkbox_content {
  color: #ffffffa3 !important;
}
.dark .holder_checkbox p {
  color: #fff;
}
.dark .input-checkboxs ul li {
  color: #ffffff96;
}
.dark .css-1dimb5e-singleValue {
  color: hsl(0deg 0% 100%);
}
.dark .css-10wo9uf-option {
  color: #fff;
}
.dark .access_act_text_btn .no_button {
  color: #fff;
}
.dark .input-field.ref_input.kyc_country_dropdown .css-13cymwt-control {
  border-color: transparent !important;
}
.dark .access_act_text_btn {
  background-color: #000;
}

.dark .deposit--title {
  color: #fff;
}

.dark .css-1nmdiq5-menu {
  background: #b9b9b9;
}
.dark .access_act_text h3 {
  color: #fff;
}
.dark .access_act_text p {
  color: #ffffffa3;
}
.dark .driver_license_content {
  color: #fff;
}
.dark .heading .inf_no {
  background: #33c2b9;
  color: #fff;
}
.dark .input-field input {
  color: #b9b9b9;
}
/* .dark .Verification.assets_main.kyc_page_main {
  background: #000;
} */
.dark .Verification.assets_main.kyc_page_main {
  /* background: #000; */
  background: #151717;
}
/* .dark .kyc_information {
  background-color: #151717;
} */
.dark .kyc_information {
  background-color: #1f1f1f;
}
.dark .ui.inline.dropdown .menu {
  background: #000;
}
.dark .no_records_text {
  color: #fff;
}
.dark .refferal_code h4 {
  color: #f7f7f7;
}
.dash_table_content td {
  text-wrap: nowrap !important;
}
.dark_profile {
  display: none;
}
/* .dark header.scrolled .MuiAppBar-colorPrimary {
  background: #000 !important;
} */

.dark header.scrolled .MuiAppBar-colorPrimary {
  background: #00000066 !important;
}

.dark
  header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary.MuiPaper-elevation4 {
  background: #000;
}

.dark .menu_new_typr a {
  color: #fff !important;
}
.dark .dark_profile {
  display: inline;
  width: 28px;
}
.dark .dark_display_none {
  display: none;
}
.dark .contact_button img,
.dark .minimage,
.dark .MuiSvgIcon-root.meus {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(154deg) brightness(113%) contrast(101%);
}
.dark .fidex_landing_main {
  background: #151717;
}
.dark .hero_section_content h1 {
  color: #fff;
}
.dark .hero_section_content p {
  color: #b9b9b9;
}
.dark .advance_trade_title {
  color: #fff;
}
.dark .footer_content p {
  color: #eaeaed;
}

.dark .copyrights {
  color: #eaeaed;
}

.dark .services h3 {
  color: #fff;
}
.dark .services li a {
  color: #b9b9b9;
}
.dark .services img {
  filter: brightness(0) saturate(100%) invert(97%) sepia(11%) saturate(69%)
    hue-rotate(188deg) brightness(99%) contrast(88%);
}
.dark .fast_tranSsaction_card {
  border: solid 1px rgba(208, 208, 208, 0.5);
  background: transparent;
}
.dark .fast_tranSsaction_card h4 {
  color: #fff;
}
.dark .fast_tranSsaction_card p {
  color: #b9b9b9;
}
.dark .keep_trade_content h2 {
  color: #fff;
}
.dark .keep_trade_content p {
  color: #b9b9b9;
}
.dark .refer_earn_section {
  background: #000;
}
.dark .dashboard_main {
  background: #000;
}

.dark .dashboard_main {
  background: #151717;
}

.dark .dashboard_main .row {
  background: #151717;
}

.dark .asset_section {
  background: #151717;
}

.dark .dashboard_title h3 {
  color: #fff;
}
.dark .dashboard_title img {
  filter: brightness(0) saturate(100%) invert(99%) sepia(7%) saturate(0%)
    hue-rotate(324deg) brightness(114%) contrast(100%);
}
.dark .buy_head .nav-link.active {
  background: transparent;
  color: #fff !important;
}
.dark .nav-tabs .nav-link {
  color: #b9b9b9 !important;
  background: transparent;
}
.dark .Buy_sell {
  border: solid 1px rgba(208, 208, 208, 0.5);
  background: transparent;
}
.dark .swap_lft_top {
  border: solid 1px rgba(208, 208, 208, 0.5);
  background: transparent;
}
.dark .foot_frst input {
  background: transparent;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.dark .swap_chng_frst .ui.selection.dropdown {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
}
.dark .register {
  background: #151717;
}
.dark .register-left h4 {
  color: #fff;
}
.dark .register-left h5 {
  color: #b9b9b9;
}
.dark .register-card {
  background: transparent;
}
.dark .nav-link.active {
  color: #fff !important;
}
.dark .input-groups .input-field {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
  color: #b9b9b9;
}
/* .dark .input-groups .input-field {
  background: transparent;
  border: solid 1px rgb(208 208 208 / 22%);
  color: #b9b9b9;
} */
.dark .foot p {
  color: #fff;
}
.dark .input-groups .input-phone {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
  color: #b9b9b9;
}
.dark .terms p {
  color: #fff;
}
.dark .react-tel-input .country-list .country {
  background: #000;
  color: #fff;
}
.dark .react-tel-input .country-list .country:hover {
  background: #33c2b9;
}
.dark .react-tel-input .flag-dropdown {
  background: transparent;
}
.dark .react-tel-input .country-list .country.highlight {
  background-color: #000000;
}
.dark .esti-container {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
  color: #b9b9b9;
}
.dark .esti-title {
  color: #fff;
}
.dark .esti-usdt {
  color: #fff;
}
.dark .esti-num {
  color: #b9b9b9;
}
.dark .table-cont {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
  color: #b9b9b9;
}
.dark .table thead {
  border: none;
  background: transparent;
}
.dark .stake-head > th {
  color: #fff;
  border: none;
}
.dark .opt-name {
  color: #fff;
}
.dark .opt-sub {
  color: #b9b9b9;
}
.dark .opt-term {
  color: #fff;
}
.dark .identification_title {
  color: #fff;
}

.dark .deposit .form_div h4 {
  color: #fff;
}
.dark .faq {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
  color: #b9b9b9;
}
.dark .opt-title {
  color: #fff;
}
.dark .ui.selection.dropdown,
.dark .ui.selection.active.dropdown .menu {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #b9b9b9 !important;
}
.dark .ui.selection.active.dropdown .menu {
  background: #000 !important;
}
/* .dark .ui.selection.active.dropdown .menu .item span{
  display: none !important;
} */
.dark .ui.dropdown .menu .selected.item,
.dark .ui.dropdown.selected {
  background: 000;
  color: #fff !important;
}
.dark .accordion-flush .accordion-item {
  background: transparent;
}
.dark .form_div .add_box {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
  color: #b9b9b9;
}

.dark .staking-rewards.staking_rewards_card {
  backdrop-filter: blur(11px);
  border: solid 1px rgba(208, 208, 208, 0.1);
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark .form_div .add_box .address {
  color: #fff;
}

.dark .accordion-flush .accordion-item .accordion-button,
.dark .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
  background: transparent;
  color: #fff;
}
.dark .accordion-button:not(.collapsed)::after {
  filter: brightness(0) saturate(100%) invert(99%) sepia(7%) saturate(0%)
    hue-rotate(324deg) brightness(114%) contrast(100%);
}
.dark .accordion-button::after {
  filter: brightness(0) saturate(100%) invert(99%) sepia(7%) saturate(0%)
    hue-rotate(324deg) brightness(114%) contrast(100%);
}
.dark input:checked + .head-toggle-label::before {
  background-color: transparent;
  color: #fff;
}
.dark .accordion-body {
  color: #b9b9b9;
}
.dark .css-1ba2zw7-MuiButtonBase-root-MuiPaginationItem-root {
  color: #fff !important;
}
.dark .head-toggle-label::after {
  background: transparent;
  color: #fff;
}
/* .dark .staking-hero {
  background: #00040c;
} */
.dark .stake-title {
  color: #fff;
}
.dark .MuiList-padding {
  background: #000;
}
.dark .frame-container {
  background-color: #000;
}
.dark .details-mail {
  color: #fff;
}
.dark .links > li > a {
  text-decoration: none;
  color: #fff;
}
.dark .btn-wrapper {
  background-color: #151717;
  border-radius: 8px;
}
.dark .details-udi {
  color: #b9b9b9;
}
.dark .history-links.active {
  color: #fff !important;
}
.dark .reward-title {
  color: #fff;
}
.dark .stake-input {
  background: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
  color: #b9b9b9;
}
.dark .stake-search-container > i {
  color: #fff;
}
.dark .buy_head {
  border: none;
}
.dark a:hover {
  color: #fff !important;
}
.dark .withdraw_title {
  color: #fff;
}
.dark .dep-drops {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}

.dark .standard_verify_box {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}
.dark .standard_verify_content {
  background: transparent;
}
.dark .identification_features,
.dark .my-refferal-eraning,
.dark .referral_content_box {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}
.dark .security_content h3,
.dark .security_email_item h3 {
  color: #fff;
}
.dark .security_settings div,
.dark .security_email_item p {
  color: #b9b9b9;
}
.dark .two_fa_heading {
  color: #fff;
}
.dark .email_id_text {
  color: #fff;
}
.dark .disabled_text p {
  color: #fff;
}
.dark .Verification h6 {
  color: #fff;
  margin-top: 0;
  /* padding-top: 80px; */
}
.dark .Verification {
  background: #151717;
}
.dark .Verification .heading {
  color: #fff;
}
.dark .two_fa_qr p {
  color: #b9b9b9;
}
.dark .input-groups .input-label {
  color: #fff;
}
.dark .staking-rewards {
  background: url("./assets/invite_earn_bg_dark.webp");
  background-repeat: no-repeat;
  margin-bottom: 30px;
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 1;
}

.dark .stake-asset {
  color: #fff;
}
.dark .refferal_code {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}
.dark .refferal_code input {
  color: #b9b9b9;
}
.dark .fidex_refferal_content {
  background: #151717;
}
.dark .referral_content_box h4 {
  color: #fff;
}
.dark .fidex_logo_light {
  display: none;
}
.dark .fidex_dark_logo {
  display: inline;
}
.fidex_dark_logo {
  display: none;
}
.dark .asset_title {
  color: #fff;
}
.dark .opt-select {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}
.dark .opt-select option {
  background: #000;
}
.dark .stake-para {
  color: #b9b9b9;
}
.dark .stake-profit {
  color: #fff;
}
.dark .stake-profit-total {
  color: #fff;
}
.dark .stake-total {
  color: #fff;
}
.dark .css-13cymwt-control {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}

.dark .Verification {
  height: 100vh;
  /* padding-top: 80px; */
}
.anti-phasing-margin {
  margin-top: 120px;
}
.dark .dash_user_name h3 {
  color: #fff;
}
/* .dark .user_id_card,
.dark .secuirty_box_content {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
} */
.dark .user_id_card,
.dark .secuirty_box_content {
  background: #1f1f1f !important;
  /* border: solid 1px rgba(208, 208, 208, 0.5) !important; */
  color: #fff !important;
}
/* .dark .secuirty_box_content2 {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
} */
.dark .secuirty_box_content2 {
  background: #1f1f1f !important;
  /* border: solid 1px rgba(208, 208, 208, 0.5) !important; */
  color: #fff !important;
}
.dark .uid_content h4 {
  color: #fff !important;
}
.dark .referral_sec_box h3 {
  color: #fff;
}
.dark .uid_content p {
  color: #b9b9b9;
}
.dark .secuirty_box_title h3 {
  color: #fff;
}
.dark .login_verify_content h4 {
  color: #fff;
}
.dark .login_verify_content p {
  color: #b9b9b9;
}
.dark .invite_text {
  color: #fff;
}
.dark .input-groups-tfa .input-field {
  color: #fff;
  background-color: #868181c7;
}
.dark .flex_input_posion .eyeicons-tfa {
  color: #fff;
}
.dark .share_btn {
  background: transparent;
  border: 1px solid rgba(208, 208, 208, 0.5);
  color: #fff;
}
.dark .convert_card,
.dark .spend_content {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}
.dark .settings {
  background: transparent;
}
.dark .set-title {
  color: #fff;
}
.dark .set-content {
  color: #b9b9b9;
}
.dark .text {
  color: #fff !important;
}
.dark .footer_light_logo {
  display: none;
}
.footer_logo_dark {
  display: none;
}
.dark .footer_logo_dark {
  display: inline;
}
.staking-rewards.staking_rewards_card {
  padding: 24px;
  border-radius: 12px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff !important;
  background-image: none;
}
.staking_price_green {
  margin-top: 0px;
  font-size: 20px;
  font-weight: 500;
  color: #33c2b9;
}
.footer_content p br {
  display: none;
}
.ui.dropdown > .text {
  text-wrap: nowrap !important;
}
.padding_staking {
  padding: 40px 0;
}
.faq_padding {
  padding-bottom: 40px;
}
.dark
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
  background: #111;
}
.dark .menu_statis a {
  color: #fff !important;
}
.dark .fidex_mobile_lightlogo {
  display: none;
}
.fidex_mobile_darklogo {
  display: none;
}
.dark .fidex_mobile_darklogo {
  display: inline;
  margin: 1rem;
}
.table_padding_bottom {
  padding-bottom: 40px;
}

.inner-32OP7ejC- {
  background: #151717 !important;
}
.invite_heading {
  font-weight: 600;
}
.name_of_fidex p {
  margin-bottom: 0px;
  font-family: "Satoshi";
}
.name_of_fidex h4 {
  margin-top: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid #a99c9c61;
  font-size: 14px;
  color: #111827;
  font-weight: 600;
  font-family: "Satoshi" !important;
}
.name_of_fidex h3 {
  font-size: 16px;
  color: #111827;
  font-weight: 600;
  font-family: "Satoshi" !important;
}
.input-field input {
  border: none;
  background: transparent;
  font-family: "Satoshi" !important;
  outline: none;
}
.ref_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fiat_deposit_name_details {
  display: flex;
  justify-content: space-between;
  /* align-items: end; */
}

.fiat_deposit_detail {
  width: 48%;
}

.ifsc_code_content {
  border-bottom: 1px solid #a99c9c61;
  padding-bottom: 12px;
}
.fill_details_text {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}
.contact_button2 {
  margin: 0px 16px;
  text-decoration: none;
  cursor: pointer;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: normal;
  color: #33c2b9 !important;
  text-wrap: nowrap;
}
.upload_icon i {
  color: #33c2b9;
  font-size: 20px;
}
.dark .name_of_fidex p {
  color: #fff;
}
.dark .name_of_fidex h4 {
  color: #f7f7f769;
}
.dark .name_of_fidex h3 {
  color: #fff;
}
.legal_name {
  width: 100%;
}
.input-field input {
  width: 100%;
}
.registered_add {
  display: flex;
  align-items: center;
  gap: 20px;
}
.input-field.ref_input.registered_add_input1 {
  width: 75%;
}
.fiat_deposit_detail1 {
  width: 100%;
}
.text_website {
  font-family: "Satoshi" !important;
  color: #33c2b9;
  font-size: 13px;
  font-weight: 400;
}
.country_kyb_dropdown h6 {
  font-size: 14px;
  font-weight: 400;
  color: #111827;
  margin-bottom: 20px;
  font-family: "Satoshi", sans-serif;
  padding-top: 12px;
}
.country_kyb_dropdown .kyc_country_dropdown {
  padding: 6px;
  border-radius: 4px;
  background-color: #f5f6f8;
}

.dark .country_kyb_dropdown .kyc_country_dropdown {
  background-color: transparent;
  border: solid 1px rgba(208, 208, 208, 0.5);
}

.fiat_deposit_detail.country_kyb_dropdown {
  width: 100%;
}
.utility_bill {
  font-family: "Satoshi";
  display: flex;
  justify-content: space-between;
}

.utility_bill p {
  color: #585d68;
}

.utility_bill i {
  color: #33c2b9;
}

.proof_of_bill {
  padding: 12px;
  border-radius: 8px;
  border: solid 0.5px #d0d0d0;
  margin-top: 12px;
}
.dark .modal_left_bg {
  background: #111;
}
.ui.selection.dropdown .menu > .item {
  margin-right: -20px !important;
}
i.dropdown.icon {
  left: 88px;
}
.spend_amt i.dropdown.icon {
  left: 8px;
}
/* .dark .font-satoshi {
  color: #fff;
} */
.dark .right-col {
  background-color: #000000c9 !important;
}
.dark .stake-flexi {
  color: #fff;
}
.dark .modal-titles,
.modal-titles-green {
  color: #ffffffc4;
}
.dark .modal-select-title {
  color: #fff;
}
.dark .modal-days {
  color: #ffffffc4;
}
.dark .modal-right-header > i {
  color: #fff;
}
.dark .modal-left-title {
  color: #fff;
}
.dark .modal-right-titles {
  color: #fff !important;
}
.dark .modal-span1 {
  color: #fff;
}
.dark .modal-input-num {
  background-color: #363636;
  color: #fff;
}
.dep-drops .dropdown.icon {
  left: 90% !important;
  top: 35% !important;
}
.dark .register .heading {
  color: #fff;
}
.visible.menu.transition {
  position: absolute;
  left: 0 !important;
}
.convert_center_box {
  padding-top: 40px;
}
.spend_amt .visible.menu.transition {
  position: absolute;
  left: -80px !important;
  top: 30px;
}

img.keep_trade_mobile_banner {
  max-width: 100%;
  z-index: 5;
  position: relative !important;
}

.keep_trade_content {
  margin-left: 36px;
}

.keep-relative {
  position: relative !important;
}

.menu_statis {
  margin-top: 13px;
}

.menu_statis > div {
  margin-bottom: 10px;
}

.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  gap: 15px;
}
.mobile_paralax1,
.mobile_paralax2 {
  display: none;
}
/* .dark .dashboard_title.active-link {
  background-color: #00000087;
} */

.dark .dashboard_title.active-link {
  background: #252629;
}

.dark .login_asset img {
  filter: contrast(0.5);
}

.tab-pad-l-10 {
  padding-left: 29px !important;
}

.tab-pad-r-10 {
  margin-right: 10px !important;
}

.security_anti_change {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
}

.w-100 {
  width: 100%;
  font-family: "Satoshi" !important;
  font-size: 14px;
  color: #111827;
  margin-top: 20px !important;
  margin-bottom: 0px;
  font-weight: 400;
}

.selectclass {
  width: 100%;
  font-family: "Satoshi" !important;
  font-size: 14px;
  color: #111827;
  margin-top: 12px !important;
  margin-bottom: 0px;
  font-weight: 400;
}
.with_new_max {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.with_new_max p {
  font-size: 16px;
  font-weight: 600px !important;
  color: #33c2b9;
}

.withdraw_new_maxinp {
  position: relative;
}

.withdraw_new_maxinp input {
  width: 100%;
}

.withdraw_new_maxinp p {
  position: absolute;
  top: 13px;
  right: 18px;
  font-size: 16px;
  font-weight: 600px !important;
  color: #33c2b9;
  cursor: pointer;
}

.stake_new_hea {
  text-align: center;
  font-family: "Satoshi" !important;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.dark .stake_new_hea {
  color: #fff;
}

.dark .stake-pop-left {
  color: #b9b9b9;
}

.dark .stake-pop-right {
  color: #fff;
}

.stak_pop_new {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 0.5px solid #33c2b9;
  padding: 12px 14px;
  border-radius: 10px;
}

.stak_pop_new_inn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* 404 -  page not found styles */

.page-not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dark .page-not-found {
  background: #151717;
}

.not-found-img-wrap {
  width: 350px;
}

.not-found-img-wrap img {
  width: 100%;
}

.not-found-title {
  font-family: "Bitter";
  font-size: 28px;
  font-weight: bold;
  color: #111827;
  margin-top: 35px;
  text-align: center;
}

.not-found-content {
  font-family: "Satoshi", sans-serif;
  font-size: 20px;
  font-weight: normal;
  color: #111827;
  text-align: center;
}

.dark .not-found-title,
.dark .not-found-content {
  color: #fff;
}

.not-found-btn {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.not-found-btn:hover {
  color: #fff !important;
}

.page-head-none {
  display: none;
}

.dark .page-img-light {
  display: none;
}

.page-img-dark {
  display: none;
}

.dark .page-img-dark {
  display: block;
}

/* circle  */

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: conic-gradient(#33c2b9 0%, transparent 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.2s ease;
  position: fixed;
  z-index: 100;
  top: 80%;
  right: 7%;
}

.inner-content {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  color: #000;
  cursor: pointer;
}
.hei_100 {
  height: 100% !important;
}
.spa_new_issu {
  display: flex;
  flex-direction: column;
  /* gap: 2px; */
  justify-content: space-between;
  /* align-items: stretch;
  height: 100%; */
}

@media (max-width: 1400px) {
  .pad-l-20 {
    padding-left: 23px;
  }
  .MuiToolbar-regular {
    padding: 0 !important;
    margin: 0 10px !important;
  }
  .discover_advance_content {
    margin-left: 50px;
  }
  .mar-top-deposit {
    margin-top: 48px !important;
  }
  .reg-row {
    justify-content: space-around;
  }
}

@media (max-width: 1399px) {
  .dark .staking-rewards.refer-box::before {
    position: absolute;
    content: "";
    width: 359px;
    height: 277px;
    top: 28px;
    border-radius: 10px;
    background-color: #353535;
    z-index: -1;
  }
}

@media (max-width: 1280px) {
  .dash_table_content td {
    text-wrap: nowrap;
  }
  img.keep_trade_mobile_banner {
    max-width: 100%;
  }
  .menu_new_typr a {
    margin: 0px 8px;
    font-size: 15px;
    text-wrap: nowrap;
  }
  .hero_section_content h1 {
    font-size: 38px;
  }
  .fast_tranSsaction_card {
    min-height: 320px;
  }
  header.scrolled .MuiAppBar-colorPrimary {
    padding: 14px 40px;
  }
  .MuiToolbar-regular {
    margin: 0;
  }
  .parallax-text img {
    width: 100%;
  }
  .MuiToolbar-regular {
    margin: 0 !important;
  }
  .dashboard_main {
    height: auto !important;
  }
}

@media (max-width: 1279px) {
  .dashboard_pages_list {
    display: none;
  }
  .dashboard_main .col-lg-2 {
    display: none;
  }
  .col-md-12 {
    width: 100% !important;
  }
  .MuiDrawer-paper {
    width: 30% !important;
  }
  /* .logo-brand img,
  .logo-brand {
    width: 90px !important;
  }
  .mobile_menu_icon {
    border-bottom: solid 1px rgba(208, 208, 208, 0.5) !important;
    padding: 2px 0 !important;
  } */
}

@media (max-width: 1200px) {
  .mar-top-deposit {
    margin-top: 48px !important;
  }
}
@media (max-width: 1024px) {
  .core-mt-20 {
    /* margin-top: 20px; */
  }
  .fast_tranSsaction_card p {
    min-height: 115px;
    /* border: 1px solid grey; */
  }
  .keep_trade_banner img {
    max-width: 100%;
  }
  .keep_trade_bg {
    width: auto !important;
  }
  .keep_trade_mobile_banner {
    width: 100% !important;
  }
  .hero_section_content h1 {
    font-size: 32px;
  }
  .hero_section_content p {
    font-size: 16px;
  }
  .hero_section_content p br {
    display: none;
  }
  .MuiDrawer-paper {
    width: 24% !important;
  }
  .mobile_menu_icon {
    border-bottom: solid 1px rgba(208, 208, 208, 0.5) !important;
    padding: 5px 0 !important;
    position: sticky;
    top: 0;
    z-index: 100;
    /* background-color: #000; */
    /* background-color: #00000066; */
    backdrop-filter: blur(5px);
  }
  .dark .staking-rewards.refer-box::before {
    width: 317px;
    height: 315px;
    top: 28px;
    left: 0px;
  }
}

@media (max-width: 1000px) {
  .parallax-text {
    /* flex-direction: row; */
    /* display: none !important; */
  }
  .mobile_paralax1 {
    display: inline !important;
  }
  .mobile_paralax2 {
    display: inline !important;
  }
  .dashboard_content {
    padding: 100px 0px 0px 0px;
  }
}
@media (max-width: 991px) {
  .referral_content_box {
    margin-bottom: 20px;
  }
  .refeferal_section {
    padding-top: 80px;
  }
  .discover_advance_content {
    margin-left: 0px;
  }
  .keep_trade_content {
    margin-left: 0px;
  }
  .dark .staking-rewards {
    background-size: cover;
  }
  .dark .staking-rewards.refer-box::before {
    width: 100%;
    height: 280px;
    top: 48px;
    left: 0px;
  }
  .referral_sec_box {
    margin-top: 41px;
  }
  .security-mt {
    margin-top: 30px;
  }
  .secuirty_box {
    border-right: none;
    padding-right: 0px;
  }
  .referral_content_box {
    height: auto;
  }
  .security_email_item.security-width-wrap {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .keep_trade_bg {
    width: 80% !important;
  }
  .fast_tranSsaction_card {
    margin-bottom: 20px;
  }
  .discover_advance_content {
    margin-top: 25px;
  }
  .buy_chicken_section {
    margin-top: 40px;
    flex-direction: row; /*newly added to show at mobile view*/
  }
  .parallax-text img {
    width: auto; /*newly added to show at mobile view*/
  }
  .services_content {
    margin-top: 25px;
  }
  .discover_advance_content {
    margin-bottom: 40px;
  }
  .MuiIconButton-label {
    gap: 6px !important;
  }
  .opt-btn-flex.table_action .span_text {
    padding-bottom: 20px !important;
  }
}
@media (max-width: 820px) {
  .hero_section_content h1 br {
    display: none;
  }
  .secrets_crypto_create {
    background-size: cover;
    margin-bottom: 20px;
  }
  .secrets_crypto_create.deposit_card {
    background-size: cover;
    margin-bottom: 20px;
  }
  .secrets_crypto_create.trade_card {
    background-size: cover;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .secrets_crypto_create p {
    height: auto;
  }
  .keep_trade_content p,
  .supported_currencies_content {
    width: 100%;
  }
  .supported_currencies {
    padding: 80px 80px 80px 80px;
  }
  .max-lg-t-3 {
    margin-top: 80px !important;
  }
  .crypto_platform_paralax {
    top: -550px;
  }
  /* .dashboard_content {
    padding: 100px 0px 56px 0px;
  } */
  .identification_title {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .standard_verify_box {
    margin-top: 25px;
  }
  .withdraw_title_content {
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .subHeader {
    padding-top: 80px;
  }
  .opt-btn-flex.table_action .span_text {
    padding-bottom: 20px !important;
    position: relative;
    bottom: 20px;
  }
  .stake-title {
    padding-top: 80px;
  }
  .deposit_top_button {
    position: relative;
    bottom: 16px;
  }
  .hero_section_content p {
    width: 100%;
  }
  .refer_earn_img {
    margin-top: 32px;
  }
  /* .refer_earn_section {
    padding: 65px 0 0;
  } */
  .MuiDrawer-paper {
    width: 33% !important;
  }
  .hero_section_banner img {
    margin-top: 23px;
  }
  .mar-top-2 {
    margin-top: 20px;
  }
  .reg-mt {
    margin-top: 20% !important;
  }
}
@media (max-width: 600px) {
  .Verification.assets_main {
    padding: 20px;
  }
  .not-found-title {
    font-size: 24px;
  }
  .not-found-content {
    font-size: 18px;
  }
  .page-not-found {
    padding: 0px 20px;
  }
}
@media (max-width: 576px) {
  .pad-lft {
    padding-left: 10px !important;
  }
  .page-inline {
    display: inline;
  }
}
@media (max-width: 540px) {
  .hero_section_content h1 {
    font-size: 25px;
  }
  .secrets_crypto_create {
    margin-bottom: 20px;
  }
  .hero_section_content p {
    font-size: 15px;
  }
  .hero_section_content p br {
    display: none;
  }
  ._started_btn {
    padding: 8px 16px;
    font-size: 14px;
  }
  .fidex_hero_section {
    padding-bottom: 20px;
    padding-top: 19px;
  }
  .secrets_crypto_title h2 {
    font-size: 24px;
  }
  .secrets_crypto_title p {
    font-size: 15px;
  }
  .secrets_crypto_create_icon h4 {
    font-size: 18px;
  }
  .secrets_crypto_create_icon img {
    width: 30px;
  }
  .secrets_crypto_create p {
    font-size: 14px;
  }
  .secrets_crypto_create p br {
    display: none;
  }
  .advance_trade_title {
    font-size: 24px;
  }
  .fast_tranSsaction_card h4 {
    font-size: 18px;
  }
  .fast_tranSsaction_card p {
    font-size: 15px;
  }
  .fast_tranSsaction_card {
    margin-bottom: 20px;
  }
  .keep_trade_bg {
    left: 0;
  }
  .discover_advance_content {
    margin-top: 40px;
  }
  .discover_advance_content h2 {
    font-size: 20px;
    color: #fff;
  }
  .discover_advance_content p {
    font-size: 14px;
  }
  .advance_trading_section_content {
    padding-bottom: 20px;
  }
  .supported_currencies {
    padding: 40px 20px;
  }
  .supported_currencies_content {
    margin-bottom: 20px;
  }
  .supported_currencies_content h4 {
    font-size: 15px;
  }
  .supported_currencies_content h2 {
    font-size: 20px;
  }
  .supported_currencies_content p {
    font-size: 15px;
  }
  .supported_currencies_content p br {
    display: none;
  }
  .keep_trade_content h2 {
    font-size: 24px;
    margin-top: 40px;
  }
  .bonus_btns {
    flex-direction: column;
  }
  .refer_earn_banner {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
  }
  .services_content {
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 25px;
  }
  .keep_trade_bg {
    display: none !important;
  }
  .secrets_crypto_title {
    margin-bottom: 40px;
  }
  .crypto_platform_paralax img {
    width: 100%;
  }
  .hero_section_content {
    margin-top: 40px;
  }
  .keep_trade_content {
    margin-top: 25px;
  }
  .stake-title {
    font-size: 24px;
  }
  .referral_content_box {
    margin-bottom: 20px;
  }
  .opt-title {
    font-size: 18px;
  }
  .pad-top-14px {
    padding-top: 12px !important;
  }

  .bg_overflow {
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
  }
  .advance_trading_section {
    padding: 23px 0;
  }
  .services li a {
    font-size: 13px;
  }
  .fiat_deposit_name_details {
    flex-direction: column;
  }
  .fiat_deposit_detail {
    width: 100%;
  }
  .upload {
    width: 100%;
  }
  .justify-content-center.cards {
    padding: 0px;
  }
  .justify-content-center.cards {
    width: 98%;
    margin: 0 auto;
  }
  .ui.selection.dropdown .menu > .item {
    margin-right: -10px !important;
  }
  .security_email_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  /* .heading .inf_no {
    padding: 1px 8px 0px 6px;
  } */
  .advance_trading_section_content {
    padding: 5px 29px 30px 27px;
  }
  .fast_tranSsaction_card p {
    min-height: auto;
  }
  .keep_trade_section {
    margin-top: 0;
    padding-top: 20px;
  }
  .keep_trade_content p br {
    display: none;
  }
  .refer_earn_section_content p br {
    display: none;
  }
  .register-card {
    margin-top: 0;
  }
  .form_div .add_box .address {
    max-width: 180px !important;
    min-width: 180px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
  }
}
@media (max-width: 480px) {
  .refeferal_section {
    padding: 0;
  }
  .subHeader {
    padding-top: 40px;
  }
  .identification_features {
    /* flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; */
    /* flex-wrap: wrap; */
    overflow: scroll;
    gap: 40px;
  }
  .stake-title {
    padding-top: 40px;
  }
  .user_id_card {
    flex-direction: column;
    align-items: self-start;
    gap: 20px;
  }
  .Swapcard.swap_drop_all {
    width: 120px;
  }
  .dark .staking-rewards.refer-box::before {
    width: 100%;
    height: 295px;
    top: 29px;
    left: 0px;
  }
  .input-mt {
    margin-top: 7px;
  }
  .dark .input-groups .input-label {
    margin-top: 27px !important;
  }
  .logo-brand img,
  .logo-brand {
    width: 90px !important;
  }
  .standard_verify_box {
    padding: 17px 19px 17px 24px;
  }
  .standard_verify_content img {
    width: 37px;
  }
  .refferal_code .ref-input-earn {
    width: 77%;
    overflow: scroll;
  }
  .my-refferal-eraning {
    padding: 21px;
  }
  .pad-l-20 {
    padding-left: 15px;
  }
  .security_email_item h3 {
    font-size: 16px;
  }
  .dashboard_content {
    padding: 30px 0px 56px 0px;
  }
  .not-found-img-wrap {
    width: 300px;
  }
}

@media (max-width: 425px) {
  /* .withdraw-p-l-24 {
    padding-left: 11px;
    padding-right: 11px;
  } */
  .dep-kyc {
    padding-left: 28px !important;
  }
  .dep-kyc p {
    padding-left: 23px !important;
  }
  .crypto_platform_paralax {
    top: -280px;
  }
  .justify-content-center.cards {
    padding: 10px;
  }
  .refer_earn_section {
    padding-top: 64px;
    padding-bottom: 40px;
  }
  .dashboard_pages_list {
    display: none;
  }
  .dashboard_content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .user_id_card {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: start;
    gap: 20px;
    padding: 14px;
    border-radius: 12px;
    background-color: #f7f7f7;
  }
  .secuirty_box {
    border-right: none;
    padding-right: 0px;
    margin-top: 0px;
  }
  .secuirty_box_content {
    padding: 20px 11px;
  }
  .pad-left-23 {
    padding-left: 23px !important;
  }
  .convert_card-wrapper {
    padding-top: 0px;
  }
  .convert_card-summary {
    padding-top: 0px;
  }
  .security_email_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .identification_section {
    padding: 40px 0;
  }
  .convert_card-wrapper.paddings_top {
    padding-top: 40px;
  }
  .hero_section_content {
    margin-top: 25px;
  }
  .MuiDrawer-paper {
    width: 57% !important;
  }
  /* .logo-brand img,
  .logo-brand {
    width: 90px !important;
  } */
  .mobile_menu_icon {
    padding: 3px 0 !important;
  }
  .fidex_hero_section {
    padding-bottom: 56px;
    padding-top: 20px;
  }
  .advance_trading_section {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .heading .inf_no {
    width: 26px;
  }
  .heading .inf_no.five {
    width: 36px;
  }
}
@media (max-width: 380px) {
  .services_content {
    gap: 20px;
  }
  .fidex_hero_section {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
@media (max-width: 375px) {
  .crypto_platform_paralax {
    top: -186px;
  }
  .security_settings {
    flex-direction: column;
    gap: 9px;
    align-items: flex-start;
  }
  .buy-rewards {
    flex-direction: column;
    justify-content: start;
    align-items: self-start !important;
    gap: 15px;
  }
  .ui.selection.dropdown .menu > .item {
    margin-right: -20px !important;
  }
  .services_content {
    justify-content: space-between;
  }
  .heading .inf_no {
    width: 26px;
  }
  /* .heading .inf_no_6 {
    width: 34px;
  } */
}
@media (max-width: 320px) {
  .crypto_platform_paralax {
    top: -69px;
  }
  .parallax-text img {
    width: 100%; /*newly added to show at mobile view*/
  }
  .buy-rewards-col {
    border: 1px solid white;
  }
  .bals_divs_insubhead {
    text-wrap: nowrap;
  }
  .services_content {
    flex-direction: column;
  }
  .services h3 {
    font-size: 21px;
  }
  .services li a {
    font-size: 15px;
  }
  .secrets_crypto_content {
    padding: 32px 30px 41px 33px;
  }
  .advance_trade_section {
    padding: 10px 0;
  }
  .advance_trading_section_content {
    padding: 13px 30px 35px 31px;
  }
  .Submit button {
    width: 100%;
    padding: 9px !important;
  }
  .total_balance_amt {
    font-size: 18px !important;
  }
  .depo-qr-content {
    text-align: left;
  }
  .refferal_code input {
    font-size: 12px;
  }
  .refferal_code {
    padding: 13px;
  }
  .refferal_code h4 {
    font-size: 12px;
  }
  .heading .inf_no {
    width: 27px;
  }
  .heading .inf_no.two {
    width: 40px;
  }
  .heading .inf_no.three {
    width: 27px;
  }
  .heading .inf_no_6 {
    width: 30px;
  }
  .heading .inf_no.five {
    width: 50px;
  }
}

.assets_main {
  height: auto;
}
.Verification.assets_main {
  height: auto;
}
.dark {
  background: #151717 !important;
}
.tradeform ul.nav a:hover {
  color: #33c2b9 !important;
}
.ui.selection.dropdown .menu > .item {
  display: flex;
  align-items: center;
}
.rights i.dropdown.icon {
  left: 90% !important;
  top: 35% !important;
}

.globe-lists {
  position: absolute;
  top: 67px;
  border: 1px solid #d0d0d0;
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
}
.cursor{
  cursor:pointer;
}

.globe-options {
  padding: 10px 40px;
  list-style-type: none;
  cursor: pointer;
  text-decoration: none;
  cursor: pointer;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: normal;
  color: #111827;
  text-wrap: nowrap;
  background: #fff;
}

.globe-options:hover {
  background-color: #33c2b9;
  color: #fff;
}

.dark .globe-options {
  color: #fff;
  background: #000;
}

.dark .globe-lists {
  border: none;
}

.dark .globe-options:hover {
  background-color: #33c2b9;
  color: #fff;
}

@media (min-width: 991px) {
  .fast_tranSsaction_card {
    height: 100%;
  }
}

@media (max-width: 1279px) {
  .globe-lists {
    top: 48px;
  }
}

.depo-qr-input {
  margin-top: -20px;
}

@media (max-width: 480px) {
  .globe-lists {
    left: 0px;
  }
  .depo-pad {
    padding: 20px;
  }
}

.textfidexcolor {
  color: aqua;
}

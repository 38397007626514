@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/satoshi");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #fff; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Target the autofill styles */
input:-webkit-autofill {
  -webkit-text-fill-color: #000; /* Set the text color */
}

.dark input:-webkit-autofill {
  -webkit-text-fill-color: #b9b9b9; /* Set the text color */
}

/* Optional: Add transition for smoother appearance */
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

.pad-l-r {
  overflow: hidden;
  padding-left: 24px;
  padding-right: 38px;
}

.pad-r-none {
  padding-right: 0px !important;
}

.pad-l-none {
  padding-left: 0px !important;
}

.textright {
  text-align: right !important;
  margin-top: 0 !important;
  font-family: "Satoshi";
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 24, 39, 0.5);
}
.dark .textright {
  text-align: right !important;
  margin-top: 0 !important;
  font-family: "Satoshi";
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.relatives {
  width: 48% !important;
}
.relativesinput {
  gap: 10px;
}
.textleft {
  text-align: left !important;
}
.dark .thead-bor-bottom {
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
  background-color: #232626;
}

.fidex-container {
  width: 95%;
  max-width: 1500px;
  margin: 0 auto;
}

.register {
  min-height: 100vh;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  background-image: url("../src/assets/Background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.textgreen {
  color: #33c2b9;
}

.register-card {
  /* width: 413px; */
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  margin-top: 25%;
}

.register .heading {
  /* height: 24px; */
  font-family: Bitter;
  font-size: 20px;
  margin-bottom: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #111827;
}

.input-groups .input-label {
  margin: 10px 0px 6px 0px;
  /* height: 19px; */
  flex-grow: 0;
  font-family: "Satoshi";
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #111827;
}

.input-groups .input-field {
  font-family: "Satoshi";
  /* height: 50px; */
  margin: 10px 0px 6px 0px;
  outline: none;
  border: none;
  color: #111827;
  font-size: 13px;
  font-weight: normal;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  background-color: #f5f6f8;
  font-weight: 400;
}

.css-hlgwow {
  padding: 0px !important;
}

.input-groups .input-field ::placeholder {
  color: #111827;
}
.input-groups-tfa .input-field {
  height: 50px;
  margin: 10px 0px 6px 0px;
  outline: none;
  border: none;
  color: #111827;
  font-size: 13px;
  font-weight: normal;
  width: 100%;
  padding: 16px 24px;
  border-radius: 4px;
  background-color: #f5f6f8;
}
.input-field-swap {
  /* height: 50px; */
  margin: 10px 0px 0px 0px;
  outline: none;
  border: none;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  /* width: 100%; */
  /* padding: 16px 24px; */
  border-radius: 4px;
  background-color: transparent;
}

.reg-select-dropdown {
  font-family: "Satoshi";
}

.custom-select__control {
  height: 50px;
  min-height: 50px;
  border: none !important;
  border-radius: 4px !important;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none !important;
  background-color: #f5f6f8 !important;
}

.dark .css-1cfo1cf {
  color: #757575 !important;
}

.custom-select__control:hover {
  border-color: none !important;
}
.custom-select__value-container {
  height: 50px;
  padding: 0 6px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.dark input[type="date"] {
  color-scheme: dark;
}

/* .dark input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(2) !important;
} */

.custom-select__input {
  margin: 0px;
}

.custom-select__indicator-separator {
  display: none;
}

.custom-select__dropdown-indicator {
  display: none !important;
}

.custom-select__indicators-container {
  height: 50px;
}

.input-groups .input-phone {
  height: 50px;
  margin: 10px 0px 6px 0px;
  outline: none;
  border: none;
  color: #111827;
  font-size: 13px;
  font-weight: normal;
  width: 100%;
  padding: 16px 50px;
  border-radius: 4px;
  background-color: #f5f6f8;
}

.react-tel-input {
  height: 50px !important;
  margin-bottom: 24px !important;
}

.react-tel-input .selected-flag {
  padding: 0px 4px !important;
}

.select-wrapper {
  position: relative;
  width: 100%;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select-arrow {
  position: absolute;
  right: 20px;
  top: 65%;
  transform: translateY(-50%);
  pointer-events: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .input-groups.icons {
  position: relative;
} */

.flex_input_posion {
  position: relative;
  width: 100% !important;
}

.flex_input_posion .eyeicons {
  position: absolute;
  right: 18px;
  top: 28px;
  height: 20px;
  width: 24px;
  cursor: pointer;
  color: #b9bbc0;
}
.flex_input_posion .eyeicons-tfa {
  position: absolute;
  right: 8px;
  top: 28px;
  height: 20px;
  width: 24px;
  cursor: pointer;
  color: #b9bbc0;
}

.qr {
  text-align: center;
}

.qr > img {
  width: 140px;
  height: 140px;
  border-radius: 20px;
}

.nav-tabs {
  border-bottom: none;
  padding: 0;
  display: flex;
  gap: 25px;
  padding: 10px 0;
}

.dark .quick__nav-tabs {
  border-bottom: 1px solid #e7e7e7 !important;
}

.nav-tabs > button {
  padding: 0;
}

.nav-tabs > button ::after {
  content: "";
  display: block;
}

.nav-tabs .nav-link {
  border: none;
  color: #888b93;
  font-family: "Satoshi";
  font-size: 16px;
}

.nav-tabs > button {
  border: 1px solid white;
}

.nav-link.active {
  color: #111827 !important;
  padding-bottom: 7px;
  border-bottom: 3px solid #33c2b9 !important;
}

.nav-link {
  color: #888b93 !important;
  border-bottom: 3px solid #d0d0d000 !important;
}

.terms {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 14px 0px;
}

.terms p {
  margin: 0px;
  font-family: "Satoshi";
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(17, 24, 39, 0.5);

  /* .I-agree-to-the-terms-and-conditions .text-style-1 {
    color: #33c2b9;
  } */
}

.terms-label {
  margin: 0px;
  font-family: "Satoshi";
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(17, 24, 39, 0.5);
  cursor: pointer;
}

.dark .terms-label {
  color: #fff;
}

.terms-label span {
  color: #33c2b9;
}

.terms p span {
  color: #33c2b9;
}

.terms a {
  color: #33c2b9;
}

.Buy_sell {
  padding: 24px 24px;
  padding-bottom: 36px;
  margin: 50px 0px;
  border-radius: 12px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
}

.Submit button {
  /* height: 50px; */
  width: 100%;
  align-self: stretch;
  padding: 14px 20px;
  border: none;
  outline: none;
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  border-radius: 6px;
  background-color: #33c2b9;
}

.foot p {
  height: 18px;
  font-family: "Satoshi";
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
  margin: 0px;
  margin-top: 24px !important;
  text-align: center;
  color: rgba(17, 24, 39, 0.5);
}

.foot p a {
  color: #33c2b9 !important;
  text-decoration: none !important;
}

.register-left img {
  width: 250px;
  display: block;
  margin: 0px auto;
}

.register-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  height: 100%;
  top: 4%;
}

.register-left h4 {
  font-family: Bitter;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  margin: 40px 0px 12px 0px;
  text-align: center;
  color: #111827;
}
.register-left h5 {
  height: 56px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Satoshi", sans-serif !important;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 24, 39, 0.7);
  font-weight: 400;
  margin: 0px !important;
}
.Verification .cards {
  border-radius: 12px;
  padding: 50px 0px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background: #fff;
}

.dark .Verification .cards {
  background: transparent;
}

.Verification h6 {
  /* height: 27px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 0;
  margin: 25px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #111827;
  font-family: "Satoshi";
}

.Verification .heading {
  font-family: Bitter;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  margin-bottom: 14px;
  letter-spacing: 0.4px;
  color: #111827;
  display: flex;
}

.notify {
  width: 100%;
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33c2b9;
  margin: 24px 0px;
  padding: 12px;
  border-radius: 4px;
  background-color: rgba(51, 194, 185, 0.2);
}

.input-groups span.textgreen {
  font-family: "Satoshi";
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33c2b9;
}

header.scrolled .MuiAppBar-colorPrimary {
  /* transition: background-color 0.3s ease; */
  /* border-bottom: 3px solid #000 !important; */
  /* box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.25) !important; */
  /* background-color: #1b8a3b !important; */
}
header.scrolled .MuiAppBar-colorPrimary {
  /* width: 1440px; */
  height: 66px;
  display: flex;
  flex-direction: row;
  transition: background-color 0.3s ease;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  margin: 0;
  /* padding: 14px 20px; */
  border-bottom: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  position: fixed;
  backdrop-filter: blur(5px);
}

.dark header.scrolled .MuiAppBar-colorPrimary {
  border-bottom: none;
}

.logo-brand {
  width: 80px !important;
  margin-right: 20px;
}

.logo-brand img {
  width: 80px !important;
}

.menu_new_typr {
  display: flex;
  align-items: center !important;
}

.menu_new_typr a {
  margin: 0px 16px;
  text-decoration: none;
  cursor: pointer;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: normal;
  color: #111827;
  text-wrap: nowrap;
}

/* .Market {
  width: 48px;
  height: 22px;
  margin: 0 28px;
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
} */

.menu_new_typr {
  display: flex;
  align-items: center !important;
}

.head-drops {
  font-family: "Satoshi";
  font-size: 16px;
  margin: 0px 16px;
  color: #111827 !important;
  font-weight: normal;
}

.head-btn {
  flex-grow: 0;
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff !important;
  /* margin-right: 12px; */
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 500;
  background-color: #33c2b9;
}

.das {
  color: #d0d0d0;
  font-size: 25px;
  font-weight: 600;
}

/* .minimage {
  width: 40px;
} */

.meus {
  color: #000 !important;
  width: 1.3em !important;
  height: 1.3em !important;
}

.menu_statis a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: normal;
  color: #111827;
}

.Buycrypto_title {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin-top: 20px;
  /* border-bottom: 1px solid #e7e7e7; */
  padding-bottom: 20px;
}

.buy_head {
  border-bottom: 1px solid #e7e7e7;
}

.buy_head .nav-tabs {
  padding: 0px !important;
}

.buy_head .nav-link.active {
  padding: 5px 0px;
}

.Buy_sell .nav-link.active {
  font-family: Bitter;
  font-size: 24px;
  font-weight: 600;
  border: none !important;
}

.Buy_sell .nav-link {
  font-family: Bitter;
  font-size: 14px;
  font-weight: 600;
  border: none !important;
}

.Buy_sell .nav-tabs {
  margin-bottom: 12px;
}

.swap_lft_top {
  height: 98px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  margin: 10px 0px !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
  gap: 10px;
  padding: 20px;
  border-radius: 12px;
  border: solid 1px #d0d0d0;
  background-color: #fff;
}

.foot_frst {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.bals_divs_insubhead {
  font-family: "Satoshi";
  font-size: 14px;
  letter-spacing: normal;
  color: #111827;
}

.foot_frst {
  width: 70%;
}

.Swapcard.swap_drop_all .ui.dropdown > .text {
  text-wrap: wrap !important;
  width: 100px;
}

.buywith_cards div {
  height: 52px;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
  margin-top: 24px;
  border: none;
  outline: none;
  display: flex;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 50s0;
  text-align: center !important;
  color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
  cursor: pointer;
}
.buywith_cards button {
  height: 52px;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
  margin-top: 24px;
  border: none;
  outline: none;
  display: flex;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 50s0;
  text-align: left;
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  border-radius: 6px;
  background-color: #33c2b9;
}

.buywith_cards button.sells {
  justify-content: center !important;
  background-color: #f45e71 !important;
}

.swap-footers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.swap-footers .swap-footL {
  font-family: "Satoshi";
  font-size: 14px;
  text-align: left;
  color: #111827;
}
.swap-footL h4 {
  flex-grow: 0;
  font-family: Bitter;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #111827;
}
.swap-footers a {
  flex-grow: 0;
  font-family: "Satoshi";
  font-size: 14px;
  text-align: left;
  color: #33c2b9 !important;
}
.swap-footers a i {
  font-size: 10px !important;
}

/* .deposit .form_div{
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 50px;
  align-items: stretch;
  gap: 12px;
} */

table > thead > tr > th {
  padding: 0px !important;
}

.deposit .form_div {
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  border-left: 2px dashed #33c2b9;
  flex-direction: column;
  padding-left: 25px;
  justify-content: flex-start;
  padding-bottom: 50px;
  align-items: stretch;
  gap: 12px;
}

.form_div.boder-none {
  border: none !important;
}

.form_div.boder-vi {
  border-left: 2px dashed #33c2b9 !important;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100% !important;
}
.deposit .form_div .sides {
  display: flex;
}
.Frame-14894 {
  width: 20px;
  height: 392px;
  flex-grow: 0;
}
.Ellipse-15 {
  width: 20px;
  height: 20px;
  flex-grow: 0;
  margin: 0 0 372px;
  padding: 0 8px 1px 7px;
  background-color: #33c2b9;
}
.Vector-19 {
  width: 1px;
  height: 373px;
  flex-grow: 0;
  margin: 19px 9.5px 12px;
  background-color: #33c2b9;
}
.form_div .add_box {
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-radius: 4px;
  background-color: #f5f6f8;
}
.form_div .rights {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.form_div .add_box .address {
  font-family: Satoshi;
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
  color: #111827;
  cursor: pointer;
}

.icon {
  cursor: pointer;
}

.deposit .form_div h4 {
  font-family: "Satoshi" !important;
  font-size: 18px;
  margin: 0px;
  position: relative;
  font-weight: 600;
  letter-spacing: 0.36px;
  color: #111827;
}

.deposit .form_div .deposit-num {
  position: absolute;
  height: 25px;
  left: -38px;
  width: 25px;
  background-color: #33c2b9 !important;
  border-radius: 50% !important;
  /* padding: 2px 9px; */
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.deposit .form_div h6 {
  font-family: "Satoshi";
  font-size: 14px;
  margin: 0px;
  letter-spacing: normal;
  color: #111827;
}
.dark .deposit .form_div h6 {
  color: #fff !important;
}
.form_div p {
  font-family: "Satoshi";
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 24, 39, 0.5);
}

.deposit-table-date {
  margin-left: 10px !important;
}

.stake-head.depo-head > th {
  padding: 10px 32px !important;
  color: rgba(17, 24, 39, 0.7);
  font-weight: 400;
}

.dark .form_div p {
  color: #fff;
}

.dep-qr {
  width: 140px;
  height: 140px;
  border-radius: 20px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
}
.dep-drops {
  height: 48px;
  color: #111827 !important;
  display: flex !important;
  font-family: Satoshi;
  border: none !important;
  outline: none !important;
  font-size: 13px;
  align-items: center !important;
  padding: 14px 16px !important;
  border-radius: 4px !important;
  background-color: #f5f6f8 !important;
}

.swap-footers .swap-footL span {
  font-family: "Satoshi";
  font-size: 14px;
  text-align: left;
  color: rgba(17, 24, 39, 0.5);
}
.swap_mid {
  margin: 12px !important;
}

.foot_frst input {
  height: 27px;
  border: none;
  font-family: "Satoshi";
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #cfd1d4;
  outline: none;
  width: 100%;
  opacity: 0.8;
  color: #000;
  background: none;
}

.swap_chng_frst .ui.selection.dropdown {
  border: none !important;
  outline: none !important;
  /* padding-bottom: 0px !important; */
}

.cursor-pointer {
  cursor: pointer;
}

.loadercss {
  height: 100vh;
  display: flex;
  /* width: 100vw !important; */
  align-items: center;
  justify-content: center;
}

.width{
  width: 71vw !important;
}

.stakeloadercss {
  /* height: 79vh; */
  display: flex;
  /* width: 83vw !important; */
  align-items: center;
  justify-content: center;
}

.loadercssss {
  /* height: 64vh; */
  display: flex;
  /* width: 70vw !important; */
  align-items: center;
  justify-content: center;
}

.loadercss-reward {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_records_text {
  text-align: center !important;
  font-family: "Satoshi";
}

.empty_data_img img {
  width: 120px !important;
  margin: 20px 0px;
}
.disabled_text {
  color: #b1b3b9 !important;
  /* opacity: 0.8; */
}
a:hover {
  color: #33c2b9;
}
.asset-action-text {
  color: #22b477;
  background: transparent;
  border: none;
}

.ref_new_main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.ref_new_social {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #33c2b9; */
}
.dark .ref_new_social i {
  color: #fff;
}
.ref-social-new {
  height: 30px;
  width: 30px;
}
.ref-socialscnd-new {
  height: 35px;
  width: 35px;
}

.ref_table_head {
  background-color: #f7f7f7;
}

.minimum button.notactive {
  padding: 8px 12px;
  border-radius: 26px;
  background-color: #33c2b9;
  border: 1px solid #33c2b9;
  cursor: pointer;
  width: 100px;
  color: #fff !important;
}

.minimum button.active {
  gap: 10px;
  padding: 8px 12px;
  border-radius: 26px;
  background-color: #fff;
  cursor: pointer;
  width: 100px;
  border: 1px solid #33c2b9;
  text-wrap: wrap;
}

.swap_fst_rght {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}
.swap_inner_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  width: 100%;
}
.ui.dropdown .menu {
  left: -45px !important;
}

.w-200 {
  width: 200px !important;
}
.w-200 .css-1dimb5e-singleValue {
  padding: 0px 6px !important;
}

.up_im_past {
  height: 110px;
  width: 110px;
}

.image_upload_kyc {
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.proofs_name {
  width: 100px;
  overflow-x: scroll;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
}
.inner_frst_display {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.drawar-signin {
  color: #33c2b9 !important;
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #33c2b9;
}

.drawar-signup {
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff !important;
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 500;
  background-color: #33c2b9;
}
.dark_display_none:hover {
  filter: brightness(0) saturate(100%) invert(63%) sepia(43%) saturate(626%)
    hue-rotate(127deg) brightness(96%) contrast(90%);
  cursor: pointer;
}

.dark_profile:hover {
  filter: brightness(0) saturate(100%) invert(63%) sepia(43%) saturate(626%)
    hue-rotate(127deg) brightness(96%) contrast(90%);
  cursor: pointer;
}

/* media query for register page */

@media (max-width: 1440px) {
  .menu_new_typr a {
    margin: 0px 13px;
  }
}

@media (max-width: 1399px) {
  .menu_new_typr a {
    margin: 0px 8px;
  }
}

@media (max-width: 1279px) {
  .das {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .register {
    padding: 0px;
    /* border: 8px solid black; */
  }
  .register-left {
    display: none;
  }
  .register-card {
    margin: 12% 0%;
  }
}

@media (max-width: 960px) {
  header.scrolled .MuiAppBar-colorPrimary {
    padding: 14px 20px !important;
  }
}

/* tablet */
@media (max-width: 768px) {
  .Verification h6 {
    /* margin: 36px 0px 20px 0px; */
  }
  .Verification .cards {
    border-radius: 12px;
    padding: 30px 20px;
    border: solid 1px rgba(208, 208, 208, 0.5);
  }
  .deposit {
    padding-left: 10px;
    padding-right: 20px;
  }
  .Buy_sell {
    margin: 20px 0px;
  }
}

@media (max-width: 500px) {
  header.scrolled .MuiAppBar-colorPrimary {
    padding: 4px 10px !important;
  }

  .minimage {
    width: 23px;
  }
  .logo-brand img,
  .logo-brand {
    width: 60px !important;
  }

  header.scrolled .MuiAppBar-colorPrimary {
    /* width: 1440px; */
    height: auto !important;
  }

  .MuiToolbar-gutters {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .useres {
    padding: 0px !important;
  }
  .moons {
    width: 25px;
    margin: 0px 5px;
  }
  .head-drops {
    margin: 0px 8px;
  }
}

/* mobile */

@media (max-width: 480px) {
  .Submit button {
    height: auto;
  }
  .pad-l-r {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 425px) {
  .Verification .cards {
    border-radius: 12px;
    padding: 30px 0px;
    border: solid 1px rgba(208, 208, 208, 0.5);
    margin: 0px 2px;
  }
}

@media (max-width: 768px) {
  .modals {
    width: 500px !important;
    height: 513px !important;
    border-radius: 12px !important;
    overflow: scroll;
  }
}

@media (max-width: 425px) {
  .modals {
    width: 400px !important;
    height: 513px !important;
  }
}

@media (max-width: 375px) {
  .modals {
    width: 340px !important;
    height: 513px !important;
  }
}

@media (max-width: 320px) {
  .modals {
    width: 300px !important;
    height: 513px !important;
  }
  .save_button {
    padding: 10px 16px !important;
  }
  .Submit button {
    font-size: 13px;
  }
  .input-groups .input-field {
    font-size: 12px;
  }
}

.email-popup-card {
  width: 414px;
  /* height: 358px; */
  padding: 26px 37px 46px 40px;
  border-radius: 12px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-popup-card.stake-claim-popup {
  padding: 18px 35px 30px 30px;
}

.dark .email-popup-card {
  background: #000;
}

.email-pop-img {
  width: 154px;
  margin: auto;
}

.email-pop-img img {
  width: 100%;
  height: 100%;
}

.email-popup-card h3 {
  font-family: "Satoshi";
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #111827;
}

.dark .email-popup-card h3 {
  color: #fff;
}

.email-popup-card p {
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #111827;
  width: 75%;
  margin: 10px auto 25px auto;
}

.email-pop-img-dark,
.email-pop-close-darkk {
  display: none;
}

.dark .email-popup-card p {
  color: #fff;
}

.dark .email-pop-img-dark {
  display: block;
}

.dark .email-pop-close-darkk {
  display: block;
}

.dark .email-pop-imgg {
  display: none;
}

.dark .email-pop-close {
  display: none;
}

.email-pop-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-right: -14px;
}

.email-pop-icon img {
  width: 100%;
  height: 100%;
}

.email-pop-btn {
  cursor: not-allowed !important;
}

@media (max-width: 425px) {
  .email-popup-card {
    padding: 26px 37px 33px 34px;
  }
}

@media (max-width: 375px) {
  .email-popup-card {
    width: 354px;
  }
}

@media (max-width: 320px) {
  .email-popup-card p {
    width: 100%;
  }
  .email-popup-card {
    width: 302px;
  }
  .email-popup-card h3 {
    font-size: 17px;
  }
}

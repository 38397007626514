@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/satoshi");

.min-height-100vh {
  min-height: 100vh;
  /* margin-top: 65px; */
}

.tradebuttun {
  padding: 8px 14px !important;
  background-color: #33c2b9;
  border-radius: 10px;
  color: #000;
}

.trade_page_global {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background-color: #e7e7e7;
  display: grid;
  gap: 1px;
  grid-template:
    "header header header header header" minmax(64px, auto)
    "left subHeader subHeader market right" 68px
    "left orderbook chart market right" 360px
    "left orderbook chart trades right" 160px
    "left orderbook orderform trades right" minmax(169px, 1fr)
    "left orderbook orderform marketActivity right" minmax(146px, auto)
    "left basictable basictable basictable right" 250px
    "footer footer footer footer footer" 24px / 1fr minmax(253px, 380px)
    minmax(510px, 1500px) minmax(253px, 380px) 1fr;
}

.dark .trade_page_global {
  gap: 0px;
  padding-top: 3px;
}

.subHeader {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  grid-area: subHeader;
  font-size: 14px;
  color: rgb(234, 236, 239);
  padding: 12px;
}
.subHeader .inner_sub {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}
.subHeader .inner_sub .content {
  min-width: 0px;
  display: flex;
  box-sizing: border-box;
  margin: 0px;
  flex: 1 1;
  flex-direction: row;
}
.subHeader .inner_sub .content .left {
  display: flex;
  box-sizing: border-box;
}
.pair_section img {
  border-radius: 50px;
  height: 18px;
  margin-right: 1px;
  width: 18px;
  margin: 6px 1px;
}
.pair_section h2 {
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
  color: #000;
}
.pair_section h2 small {
  font-weight: normal;
  color: rgb(17 24 39);
}

.css-1qkq70v .left .layout {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0px;
  flex: 0 0 auto;
}
.subHeader .inner_sub .content .left .layout .childrenContainer {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
}
.css-l36dyj {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  /* padding-right: 24px; */
  height: auto;
  justify-content: space-around;
  /* border-right: 0px solid #fff; */
  margin-right: 24px;
  margin-left: 8px;
  height: 48px;
  border-right-width: 1px;
}
.subHeader .inner_sub .content .left .nowPrice {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.css-13n52y {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: #2ebd85;
}
.subHeader .inner_sub .content .left .nowPrice .showPrice {
  font-family: "Satoshi", sans-serif;
  font-size: 20px;

  text-align: left;
}
.subHeader .inner_sub .content .left .nowPrice .subPrice {
  font-size: 12px;
  color: rgba(17, 24, 39, 0.8);
  line-height: inherit;
}
.markets {
  color: rgba(17, 24, 39, 0.5) !important;
}
.css-10nf7hq {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-as916g {
  /* box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    min-width: 0px;
    padding-left: 3px;
    padding-right: 3px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 2px;
    font-weight: 400;
    color: rgb(240, 185, 11);
    background-color: rgb(54, 50, 29);
    display: inline-block; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "Satoshi", sans-serif;
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #33c2b9;
  gap: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: rgba(51, 194, 185, 0.2);
  cursor: pointer !important;
}
.css-4cffwv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
}
.css-as916g .tag-text {
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.css-as916g .tag-text .tag {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.css-as916g .tag-margin {
  color: rgb(240, 185, 11);
  opacity: 0.25;
  margin-left: 5px;
  margin-right: 5px;
}
.css-omng2l {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: currentcolor;
  font-size: 12px;
  fill: currentcolor;
  width: 1em;
  height: 1em;
}
.css-4h6mys {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
}
.css-f4kgqr a {
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  text-decoration: none;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
}
.dark .css-f4kgqr a {
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  text-decoration: none;
  text-align: left;
  color: #fff;
}
.subHeader .inner_sub .content.right {
  flex: 1 1 0%;
  flex-flow: row;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-width: 0px;
  overflow: hidden;
}
.subHeader .inner_sub .right .tickerListContainer {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin-left: 32px;
  margin-right: 8px;
  overflow: hidden;
}
.subHeader .inner_sub .right .tickerListContainer .tickerList {
  white-space: nowrap;
  overflow: auto hidden;
}

.subHeader .inner_sub .right .tickerListContainer .tickerList > div {
  display: inline-block;
  padding-right: 1.4vw;
}
.layout h1 {
  margin: 0;
  /* margin: 0 8px 0 0; */
  font-family: "Satoshi", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #111827;
}
.layout {
  display: flex;
}
.tickerPriceText span {
  display: flex;
}

.subHeader .inner_sub .right {
  flex: 1 1 0%;
  flex-flow: row;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-width: 0px;
  overflow: hidden;
  flex-grow: 1;
  position: static;
}

.subHeader .inner_sub .right .tickerListContainer .tickerList .tickerItemLabel {
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  color: rgba(17, 24, 39, 0.8);
  margin-right: 0px;
  margin-bottom: 8px;
  line-height: 16px;
  font-weight: 400;
  /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
}
.subHeader .inner_sub .right .tickerListContainer .tickerList .tickerPriceText {
  font-size: 12px;
  color: #111827;
}
.price_red {
  color: rgb(246, 70, 93);
}

.order_form {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  grid-area: orderform;
  background-color: #fff;
}

.order_book {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  grid-area: orderbook;
  position: relative;
  /* padding-top: 16px; */
  padding-bottom: 12px;
}
.span-div {
  padding: 12px;
  border-bottom: 1px solid #e7e7e7;
}

.dark .span-div {
  border-bottom: 1px solid #ffffff29;
}

.chart_trade {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  grid-area: chart;
  position: relative;
}
.trades {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  grid-area: trades;
  /* padding-left: 16px;
    padding-right: 16px; */
  padding-bottom: 16px;
  background-color: #fff;
  z-index: auto;
  position: relative;
  overflow: hidden;
}

.dark .trades {
  border: 1px solid #ffffff29;
  border-top: none;
}

.markets {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  grid-area: market;
}
.basictable {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  min-height: 276px;
  width: 100%;
  background-color: #fff;
  grid-area: basictable;
  font-size: 14px;
  color: rgb(234, 236, 239);
  flex-direction: column;
  /* overflow: hidden; */
  padding-bottom: 20px;
}

.dark .basictable {
  border-top: 1px solid #ffffff29;
  padding-bottom: 10px;
}
.orderbook-header {
  -webkit-box-align: center;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  position: relative;
  -webkit-box-pack: initial;
  justify-content: initial;
}
.orderbook-header .orderbook-header-tips {
  display: flex;
  -webkit-box-align: center;
  padding: 0px 8px;
  align-items: center;
}
.orderbook-header .orderbook-header-tips button {
  margin: 0px 4px 0px 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  outline: none;
  line-height: 20px;
  padding: 0px 4px;
  border: none;
  border-radius: 2px;
  width: 36px;
  min-width: auto;
  height: 32px;
  background-color: transparent;
  opacity: 1;
  /* flex: 1 1 0%; */
}
.orderbook-header .orderbook-header-tips button svg {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: currentcolor;
  font-size: 24px;
  fill: currentcolor;
  width: 1em;
  height: 1em;
}
.orderbook-header .orderbook-header-tips button svg {
  color: rgb(132, 142, 156);
}

.market_order table {
  width: 100%;
}

.market_order {
  padding: 0px 16px;
}
.market_order table {
  width: 100%;
}
.market_order table th {
  color: rgba(17, 24, 39, 0.5);
  font-size: 12px;
  font-weight: normal;
}

.market_order table {
  table-layout: fixed;
}
.market_order th {
  font-weight: bold;
}
.market_order th,
.market_order td {
  text-align: right;
  color: #111827;
  font-weight: normal;
  font-size: 12px;
}
.market_order article {
  height: auto;
  display: flex;
  flex-direction: column;
}
.market_order .divider {
  background: #fff;
}
.market_order .orderbook-header {
  background: transparent;
}
.market_order .current-price {
  font-weight: 400;
  font-size: 16px;
  padding: 6px 0px;
  color: #000;
}
.market_order .side {
  height: 50%;
  overflow-y: auto;
}
.market_order .price.sell {
  color: rgb(246, 70, 93);
  text-align: left;
}
.market_order .price.buy {
  color: #2ebd85;
  text-align: left;
}
.market_order table th.price {
  text-align: left;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.current-price span {
  color: rgb(132, 142, 156);
  font-size: 13px;
  margin-left: 10px;
}
.order_form .nav-pills {
  display: flex;
  background: #fff;
}
.order_form .nav-pills li {
  display: flex;
}
.order_form .nav-pills li a,
.order_form .nav-pills li a:hover {
  display: flex;
  padding: 11px 30px;
  text-transform: capitalize;
  color: rgb(132, 142, 156);
  text-decoration: none !important;
  font-family: "Satoshi";
}
.order_form .nav-pills li a.active {
  display: flex;
  padding: 11px 30px;
  border-top: 3px solid #33c2b9;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  background: #fff;
}
.tradeform ul.nav {
  background: transparent;
  margin-top: 10px;
  cursor: pointer;
}
.tradeform ul.nav a,
.tradeform ul.nav a:hover {
  padding: 0px;
  border: none;
  padding: 0px 10px;
  font-size: 12px;
  color: #111827;
}
.buy_BTN button,
.buy_BTN button:hover {
  text-transform: capitalize;
  background: rgb(14, 203, 129);
  color: #fff;
  width: 100%;
  font-weight: 400;

  height: 39px;
  padding: 10px;
  border-radius: 6px;
  background-color: #33c2b9;
}
.buy_BTN button.Sell {
  background: #f45e71;
}
.slider_spacing {
  padding: 0px 10px;
}
.tradeform ul.nav a.active {
  border: none;
  font-size: 12px;
  font-weight: normal;
  color: #2ebd85;
  padding: 0px 10px;
}
.form_trade {
  padding: 10px 10px;
  padding-bottom: 15px;
}
.form_trade .avali {
  color: #a1a7b0;
  font-size: 13px;
  margin-bottom: 10px;
}
.form_trade .avali p {
  margin: 0;
}
.form_trade .avali p span {
  margin-left: 5px;
  color: #fff;
  font-family: Satoshi;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
}

.form_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  background: #fff;
  margin-bottom: 12px;
  height: 36px;
  padding: 10px 12px;
  border-radius: 4px;
  border: solid 1px rgba(208, 208, 208, 0.5);
}
.form_right label {
  margin: 0;
  font-family: "Satoshi";
  font-size: 12px;
  color: rgba(17, 24, 39, 0.5);
  text-wrap: nowrap !important;
}
.form_right .input_section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.form_right .input_section input {
  background: transparent;
  border: none;
  color: #6c757d;
  height: 40px;
  box-shadow: none;
  flex-grow: 1;
  text-align: right;
  outline: none;
}
.form_right .input_section span {
  margin-left: 5px;
  font-family: "Satoshi";
  font-size: 12px;
  text-align: left;
  color: #111827;
}
/* .form_seldect__pair {
    padding: 10px;
  } */
.form_seldect__pair .searcj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  margin: 12px;
  border-radius: 4px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  gap: 9px;
}
.searcj i {
  color: rgb(94, 102, 115);
}
.form_seldect__pair .searcj input {
  height: 18px;
  font-size: 14px;
  flex-grow: 1;
  background: transparent;
  outline: none;
  border: none;
  color: rgba(17, 24, 39, 0.8);
}
.dark .form_seldect__pair .searcj input {
  height: 18px;
  font-size: 14px;
  flex-grow: 1;
  background: transparent;
  outline: none;
  border: none;
  color: #fff;
}
.form_seldect__pair ul {
  display: flex;
  gap: 5px;
  /* margin-top: 5px; */
  padding: 0px 12px;
  border-bottom: 1px solid #e7e7e7;
}

.dark .form_seldect__pair ul {
  border-bottom: 1px solid #ffffff29;
}

.form_seldect__pair ul li a,
.form_seldect__pair ul li a:hover {
  text-decoration: none;
  padding: 1px 5px;
  border-radius: 2px;
  font-size: 14px;
  font-family: Satoshi;
  font-weight: 500;
  color: rgb(0 0 0 / 88%);
  cursor: pointer;
}
.form_seldect__pair ul li a.active {
  background: #fff;
  font-family: Satoshi;
  border-bottom: 3px solid #33c2b9;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 2px;
  cursor: pointer;
  color: #33c2b9;
}
.pair_details .fixTableHead {
  overflow-y: auto;
  height: 340px;
  padding: 0px 12px 12px 12px;
  margin-top: 20px !important;
}

.pair_details .fixTableHead thead th {
  position: sticky;
  top: 0;
}
.pair_details table {
  border-collapse: collapse;
  width: 100%;
  /* margin-top: 24px !important; */
}
.pair_details th {
  background: transparent;
  font-family: Satoshi;
  padding: 6px 0px;
  font-size: 12px;
  width: 30%;
  color: #000;
  font-weight: 400;
  /* text-align: center !important; */
}
.pair_details th {
  background: #fff;
}
.pair_section {
  margin: 8px 0px !important;
}
.pair_details p {
  color: #fff !important;
  font-size: 12px;
  margin: 0px;
  text-align: right;
}
.pair_details p span {
  color: #222325;
}

.dark .pair_details p span {
  color: rgb(183, 189, 198);
}

.pair_details td {
  font-size: 12px;
  cursor: pointer;
  color: rgb(183, 189, 198);
  font-weight: 300;
  padding: 3px 0px;
  font-size: 12px;
  color: #222325;
  font-family: "Satoshi";
  font-size: 12px;
  line-height: normal;
  /* text-align: right; */
  font-weight: 300;
  padding: 13px 0px;
}

.dark .pair_details td {
  color: rgb(183, 189, 198);
}

.text-right {
  text-align: right;
}

.order_book span {
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: 500;
  color: rgba(17, 24, 39, 0.8);
}

.text-green {
  color: rgb(14, 203, 129);
}

.trade-text-green {
  color: rgb(12, 218, 139);
}

.trades .form_seldect__pair ul li a.active {
  background: transparent;
  font-weight: 400;
}
.trades .pair_details .fixTableHead {
  overflow-y: auto;
  height: 290px;
}

.basictable.tradeform ul.nav a,
.basictable.tradeform ul.nav a:hover {
  border: none;
  padding: 0px 10px;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(17, 24, 39, 0.5);
}
.basictable.tradeform ul.nav a.active {
  color: #33c2b9 !important;
}
.green_content tr {
  position: relative;
}
.green_content tr::before {
  position: absolute;
  content: "";
  height: 30px;
  background: #daefe1;
  right: 0;
  left: auto;
  width: attr(data-width);
  display: none;
}
.pading_oedar {
  margin: 0px 10px;
  padding: 24px 0px 24px 0px;
  border-radius: 12px;
  border: solid 1px #e7e7e7;
  background-color: #fff;
  padding-top: 0;
  overflow: hidden;
}

.pair_details.pading_oedar th {
  background: #e0e3eb !important;
  padding: 14px 0px !important;
  font-size: 13px;
  text-align: center;
  width: auto;
  border-bottom: 1px solid #cccccc21;
  font-family: "Satoshi";
  font-size: 12px;
  color: #000;
}

.dark .pair_details.pading_oedar th {
  background: #42404080 !important;
}

.pair_details.pading_oedar td {
  font-size: 13px;
  color: rgb(14 14 14);
  font-weight: 400;
  padding: 20px 0px;
  text-align: center;
  border-bottom: 1px solid #cccccc21;
}

.dark .pair_details.pading_oedar td {
  color: rgb(183, 189, 198);
}

/* .pair_details.pading_oedar tbody tr:hover {
    background-color: rgb(43, 49, 57);
  } */
.pair_details.pading_oedar tbody tr {
  transition: all 0.2s ease-in 0s;
}

.pair_details.pading_oedar tbody tr:hover > td {
  border-color: transparent;
}

.text-red {
  color: rgb(246, 70, 93) !important;
}

.dark .text-red {
  color: rgb(246, 70, 93) !important;
}

.css-1bea9fq {
  box-sizing: border-box;
  margin: -1px 0px 0px;
  min-width: 0px;
  grid-area: footer;
  background-color: #181c2a;
  padding: 0px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(37, 41, 48);
  border-right-color: rgb(37, 41, 48);
  border-bottom-color: rgb(37, 41, 48);
  border-left-color: rgb(37, 41, 48);
}
.css-1bea9fq.footer-sticky {
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: 0px;
  padding: 4px 0px;
}
.css-fnk7g1 .previewItemWrap .change {
  font-size: 12px;
  margin-right: 8px;
}
.css-fnk7g1 .previewItemWrap .sell {
  color: rgb(246, 70, 93);
}
.css-1vrz22u {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 138px;
}
.css-19fju8h {
  box-sizing: border-box;
  margin: 0px 0px 0px 10px;
  min-width: 0px;
  color: rgb(14, 203, 129);
  font-size: 12px;
}
.css-14fo47n {
  box-sizing: border-box;
  margin: 0px 0px 0px 32px;
  min-width: 0px;
  flex: 1 1 0%;
  display: flex;
  height: 16px;
  -webkit-box-align: center;
  align-items: center;
}
.css-14fo47n .foot-line {
  margin-top: 3px;
  width: 1px;
  height: 10px;
  background-color: rgb(71, 77, 87);
}
.css-18shdj7 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  outline: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-1aebr02 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-15gs5hm {
  box-sizing: border-box;
  margin: 2px 0px 0px 16px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 16px;
  fill: rgb(132, 142, 156);
  width: 1em;
  height: 1em;
}
.css-7tgotf {
  box-sizing: border-box;
  margin: 0px 0px 0px 2px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  overflow: hidden;
}
.css-7tgotf .animation {
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: animation-1hgl3te;
  animation-delay: 2s;
}
.css-7tgotf .container11 {
  display: flex;
  flex-wrap: nowrap;
  animation-duration: 431s;
}
.css-fnk7g1 {
  box-sizing: border-box;
  min-width: 0px;
  display: flex;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
@-webkit-keyframes animation-1hgl3te {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes animation-1hgl3te {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.css-fnk7g1 .previewItemWrap {
  margin-left: 8px;
  display: flex;
  cursor: pointer;
}
.css-fnk7g1 .previewItemWrap {
  margin-left: 8px;
  display: flex;
  cursor: pointer;
}
.css-fnk7g1 .previewItemWrap .symbol {
  color: rgb(183, 189, 198);
  font-size: 12px;
  margin-right: 8px;
}
.css-fnk7g1 .previewItemWrap .change {
  font-size: 12px;
  margin-right: 8px;
}
.css-fnk7g1 .previewItemWrap .buy {
  color: rgb(14, 203, 129);
}
.css-fnk7g1 .previewItemWrap .lastPrice {
  font-size: 12px;
  color: rgb(132, 142, 156);
  margin-right: 12px;
}
.css-14fo47n .foot-line {
  margin-top: 3px;
  width: 1px;
  height: 10px;
  background-color: rgb(71, 77, 87);
}
.markert_activity {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  grid-area: marketActivity;
}

.dark .markert_activity {
  border: 1px solid #ffffff29;
  border-top: none;
  border-bottom: none;
}

.css-13fs1br {
  box-sizing: border-box;
  margin: 0px 16px;
  min-width: 0px;
  display: flex;
  position: relative;
  z-index: 1;
  padding-top: 12px;
  padding-bottom: 10px;
  height: 40px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 500;
  color: #111827;
}
.markert_activity ul.nav {
  padding: 0px 16px;
  margin-bottom: 10px;
  gap: 6px;
}
a {
  text-decoration: none !important;
}
.markert_activity ul.nav a {
  display: flex;
  font-size: 13px;
  color: #a0a6af;
  padding: 0px 8px;
  text-decoration: none;
  font-weight: 300;
  font-family: Satoshi;
  font-size: 12px;
  text-align: left;
  color: rgba(17, 24, 39, 0.8);
}
.markert_activity ul.nav a.active {
  padding: 2px 8px;
  border-radius: 8px;
  background-color: rgba(17, 24, 39, 0.1);
}
.css-14d05gv:hover {
  text-decoration: none;
}
.css-14d05gv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  margin: 10px 0px;
  height: 41px;
  flex: 0 0 auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-decoration: none;
}
.pading_tabs_content {
  padding: 1px 16px;
}
.css-1pysja1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
}
.css-qt6vj7 {
  box-sizing: border-box;
  margin: 0px 0px 2px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 12px;
}
.css-qt6vj7 {
  box-sizing: border-box;
  margin: 0px 0px 2px;
  min-width: 0px;
  color: rgba(17, 24, 39, 0.8);
  font-size: 12px;
}
.css-1iqe90x {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgba(17, 24, 39, 0.8);
}
.css-21cgr0 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgba(17, 24, 39, 0.4);
  font-size: 12px;
}
.css-m3c6zl {
  box-sizing: border-box;
  margin: 0px;
  flex: 1 1 0%;
  text-align: right;
  overflow: hidden;
  min-width: 96px;
}
.css-4na7jw {
  box-sizing: border-box;
  margin: 0px 0px 2px;
  min-width: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(17, 24, 39, 0.8);
  font-size: 12px;
}
.css-wk8c7j {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(17, 24, 39, 0.4);

  font-size: 12px;
  padding-bottom: 1px;
}
.css-vjdxdv {
  box-sizing: border-box;
  margin: 0px;
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: end;
  justify-content: flex-end;
  min-width: 54px;
}
.css-ao5z3i {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  position: relative;
  width: 54px;
  height: 24px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.css-1qlplmi {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #2ebd85 !important;
  border-radius: 4px;
  opacity: 100%;
}
.css-3kwgah {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: currentcolor;
  font-size: 24px;
  fill: currentcolor;
  width: 1em;
  height: 1em;
}
.css-ao5z3i > svg {
  color: rgb(255 255 255);
  fill: rgb(255 255 255);
  position: relative;
  font-size: 32px;
}
/*Trade Dark Mode */

/* .dark .trade_page_global {
  background-color: #000000;
} */
.dark .trade_page_global {
  background-color: #151717;
}
.dark .subHeader {
  background: #151717;
  border: 1px solid #ffffff29;
  border-right: none;
}
.dark .layout h1 {
  color: #fff;
}
.dark .subHeader .inner_sub .content .left .nowPrice .subPrice {
  color: #b9b9b9;
}
.dark
  .subHeader
  .inner_sub
  .right
  .tickerListContainer
  .tickerList
  .tickerItemLabel {
  color: #ffffff;
}
.dark
  .subHeader
  .inner_sub
  .right
  .tickerListContainer
  .tickerList
  .tickerPriceText {
  color: #b9b9b9;
}
.dark .markets {
  background-color: #151717;
  border: 1px solid #ffffff29;
}
.dark .form_seldect__pair .searcj {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}
.dark .pair_details th {
  background: transparent !important;
  border: none;
  color: #fff !important;
}
.dark .pair_section h2 {
  color: #fff;
}
.dark table > thead,
tfoot > tr {
  border: none;
}
.dark .form_seldect__pair ul li a,
.form_seldect__pair ul li a:hover {
  color: #b9b9b9;
}
.dark .form_seldect__pair ul li a.active {
  background: transparent;
  color: #fff;
}
.dark .order_book {
  background: #151717;
  border-left: 1px solid #ffffff29;
  border-right: 1px solid #ffffff29;
}

.dark .market_order th,
.dark .market_order td {
  color: #fff;
}

.dark .pading_oedar {
  background: #151717 !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}

.dark .basictable,
.dark html.theme-dark .chart-page {
  background: #151717;
}
html.theme-dark .inner-1xuW-gY4- {
  background-color: #000;
}
.dark .order_form .nav-pills,
.dark .order_form,
.dark .trades,
.dark .markert_activity {
  background: #151717;
}
.dark .form_right {
  background: transparent !important;
  border: solid 1px rgba(208, 208, 208, 0.5) !important;
  color: #fff !important;
}
.dark .basictable.tradeform ul.nav a,
.basictable.tradeform ul.nav a:hover {
  color: #fff;
}
.dark .form_trade .avali {
  color: #fff;
}
.dark .form_trade .avali p span {
  color: #b9b9b9;
}
.dark .form_right label {
  color: #fff;
}
.dark .form_right .input_section span {
  color: #fff;
}
.dark .css-13fs1br {
  color: #fff;
}
.dark .market_order .current-price {
  /* border: solid 1px rgba(208, 208, 208, 0.5) !important; */
  color: #fff !important;
  background: #151717 !important;
}
.dark .chart-page {
  background: #1c2b4a !important;
}
.dark .toolbar-2MJefnwP- {
  background: #151717 !important;
}
.group-2JyOhh7Z- {
  background: #151717 !important;
}
.dark .order_form .nav-pills li a.active {
  /* border: solid 1px rgba(208, 208, 208, 0.5) !important; */
  color: #2ebd85 !important;
  background: transparent;
}
.dark .order_book span {
  color: #fff !important;
}
.dark .tradeform ul.nav a,
.tradeform ul.nav a:hover {
  color: #fff;
}
.dark .css-qt6vj7 {
  color: #b9b9b9;
}
.dark .css-1iqe90x {
  color: #fff;
}
.dark .markert_activity ul.nav a {
  color: #fff;
}
.dark .css-21cgr0 {
  color: #fff;
}
.dark .css-4na7jw {
  color: #fff;
}
.dark .css-wk8c7j {
  color: #fff;
}
.dark .toolbar-2MJefnwP- {
  background: #151717 !important;
}
.dark .inner-1xuW-gY4- {
  background: #151717 !important;
}
.dark .pair_details th {
  background: #151717 !important;
}
.dark audio,
.dark canvas,
.dark progress,
.dark video {
  background: #151717;
}
.dark .wrap-3tiHesTk- {
  background: #151717;
}
.dark .fa-spinner:before {
  color: #fff;
}
.dark .group-2JyOhh7Z- {
  background: #151717 !important;
}
.dark .trade_dark canvas {
  background: #151717 !important;
}
html.theme-dark .chart-page .chart-container-border {
  background: #000 !important;
}
.group-2JyOhh7Z- + .group-2JyOhh7Z- {
  margin-top: 1px;
}
.dark .chart_trade {
  background: #151717 !important;
}
.dark .Buycrypto_title {
  color: #fff;
}
.dark .swap-footL h4 {
  color: #fff;
}
.dark .bals_divs_insubhead {
  color: #ffff;
}
.dark .swap-footers .swap-footL span {
  color: #b9b9b9;
}
.dark .swap-footers .swap-footL {
  color: #b9b9b9;
}
.dark .swap_change_clr {
  color: #b9b9b9 !important;
}
.dark .input-field-swap {
  color: #fff;
}
.dark .asset-btn {
  background: transparent;
}
.dark .identify-title {
  color: #fff;
}
.dark hr {
  border-color: rgba(208, 208, 208, 8);
}
.dark .swap_balance span {
  color: #fff;
}
.dark .swap_balance {
  color: #fff;
}
.dark .theme-dark .group-wWM3zP_M- {
  background-color: #151717 !important;
}
html.theme-dark .button-2ioYhFEY- {
  color: #787b86;
  background: #151717;
}
.button-2ioYhFEY- {
  color: #fff !important;
  background: #151717 !important;
}
.inner-1xuW-gY4- {
  background: #000 !important;
}
.chart-page.unselectable.on-widget {
  background: #000 !important;
}
.dark .group-2JyOhh7Z- {
  background: #000 !important;
}
.dark .toolbar-2n2cwgp5- {
  background: #000 !important;
}
html.theme-dark .group-2JyOhh7Z- {
  background-color: #131722;
  background: #000 !important;
}
.dark .chart_trade {
  background: #151717 !important;
}
.dark .input-3lfOzLDc- {
  background-color: #000 !important;
}
.dark html.theme-dark .button-2ioYhFEY- {
  color: #787b86;
  background: #000;
}
.table > :not(caption) > * > * {
  text-wrap: nowrap !important;
}
@media only screen and (max-width: 768px) {
  .trade_page_global {
    /* Make the grid 1 column when the screen width is 768px or less */
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-areas:
      "header"
      "left"
      "subHeader"
      "orderbook"
      "chart"
      "market"
      "trades"
      "orderform"
      "marketActivity"
      "basictable"
      "footer";
  }
  .pair_details.pading_oedar td {
    min-width: 130px;
  }
  .dark .trade_page_global {
    padding-top: 67px;
  }
  .dark .subHeader {
    border-right: 1px solid #ffffff29;
  }
}

@media only screen and (max-width: 480px) {
  /* Make the header smaller when the screen width is 480px or less */
  header {
    grid-area: header;
    height: 50px;
  }
  .pair_details.pading_oedar td {
    min-width: 130px;
  }

  /* Make the grid items smaller when the screen width is 480px or less */
  #left {
    grid-area: left;
  }
  #subHeader {
    grid-area: subHeader;
  }
  #market {
    grid-area: market;
  }
  #right {
    grid-area: right;
  }
  #orderbook {
    grid-area: orderbook;
  }
  #chart {
    grid-area: chart;
  }
  #trades {
    grid-area: trades;
  }
  #orderform {
    grid-area: orderform;
  }
  #marketActivity {
    grid-area: marketActivity;
  }
  #basictable {
    grid-area: basictable;
  }
  #footer {
    grid-area: footer;
  }

  #left,
  #subHeader,
  #market,
  #right,
  #orderbook,
  #chart,
  #trades,
  #orderform,
  #marketActivity,
  #basictable {
    font-size: 14px;
    padding: 8px;
  }

  .tab-content.order-table-row th {
    padding: 25px 20px !important;
    text-wrap: nowrap;
  }
  .dark .trade_page_global {
    padding-top: 16px;
  }
  .layout h1 {
    font-size: 18px;
  }
  .subHeader .inner_sub .content {
    flex-direction: column;
    gap: 27px;
  }
  .css-l36dyj {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    /* padding-right: 24px; */
    height: auto;
    justify-content: space-around;
    /* border-right: 0px solid #fff; */
    margin-right: 24px;
    margin-left: 0px;
    height: 48px;
    border-right-width: 1px;
  }
  .subHeader .inner_sub .right .tickerListContainer {
    margin-left: 0px;
    margin-right: 0px;
  }
  .subHeader .inner_sub .right .tickerListContainer .tickerList {
    display: flex;
    gap: 13px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .subHeader
    .inner_sub
    .right
    .tickerListContainer
    .tickerList
    .tickerItemLabel {
    font-size: 11px;
  }
  .subHeader
    .inner_sub
    .right
    .tickerListContainer
    .tickerList
    .tickerPriceText {
    font-size: 15px;
  }
}

.css-1dynufi-MuiSlider-root {
  color: rgba(208, 208, 208, 0.5) !important;
  height: 6px !important;
  width: 100%;
  padding: 13px 0;
}
.css-1dynufi-MuiSlider-root .MuiSlider-track {
  background-color: #888b93 !important;
}

.Butn_header {
  background-color: #33c2b9 !important;
}

/*  */
.dark .input-3lfOzLDc- {
  /* background-color: #fff !important; */
}
